@use "src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;

  width: 100%;
  background-color: #595EB6;
  border-radius: 24px;
  padding: 16px;
  min-height: 160px !important;

  @media screen and (min-width: variables.$media_l) {
    min-height: unset !important;
    height: 100px !important;
  }

}

.title {
  font-family: "Chillax-Semibold" !important;
  font-size: 18px !important;
  color: #FFF !important;
}

.link {
  font-family: 'Chillax-Light' !important;
  font-size: 18px !important;
  text-decoration: underline !important;
  color: #fff !important;
}
