@use "src/assets/styles/variables";

.container {
  height: 100%;
  width: 250px;
  min-width: 250px;
  padding: 6px 32px;
  border-radius: 16px;
  background-color: #595eb6;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;
}

.iconButton {
  * {
    fill: #FFF !important;
  }
}