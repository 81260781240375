@use "src/assets/styles/variables";

.contactUsContainer {
  background-color: #595EB6;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-radius: 15px;
  z-index: 99;
  overflow-y: auto;
  align-items: center;

  & > * {
    max-width: 1100px;
    width: 100%;
  }

  @media screen and (min-width: variables.$media_xxl) and (min-height: 940px) {
    justify-self: flex-end;
    background-color: unset;
  }
}

.contactUsContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  @media screen and (min-width: variables.$media_xxl) and (min-height: 940px) {
    background-color: #595EB6;
    padding: 20px;
    border-radius: 15px;
  }
}

.submitWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;

  @media screen and (min-width: variables.$media_xxl) and (min-height: 940px) {
    display: grid;
    grid-template-columns: 70% 1fr;
    align-items: center;
  }
}

.label {
  margin-bottom: -5px !important;
  margin-left: 20px !important;
  color: white !important;
}

.link {
  color: #FF8552 !important;
  transition: color 0.3s;

  &:hover {
    color: #b76341 !important;
  }
}

.policy {
  font-family: 'Chillax-Regular' !important;
  color: #fff !important;
  font-size: 16px !important;
}

.inputWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.contactUsTitle {
  color: whitesmoke;
}

.button {
  width: 100% !important;
  height: 60px !important;
  border: 3px solid white !important;
  background-color: #FF8552 !important;
  border-radius: 12px !important;
  text-transform: none !important;
  color: #fff !important;
  font-family: "Chillax-Regular" !important;
  font-size: 20px !important;
}

.button:disabled {
  background-color: #999998 !important;
}
