.container {
  width: 100%;
  margin: 0 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: linear-gradient(308.23deg, #2ba19a 20.09%, #012585 139.53%);
  mix-blend-mode: normal;
  border-radius: 140px;
  padding: 0 15px;

  @media screen and (min-width: 996px) {
    max-width: 1180px !important;
    margin: 0 40px;
  }
}

.search {
  position: absolute !important;
  top: 50%;
  right: 0;
  width: 40px;
  height: 40px;
  transform: translate(-20%, -50%);
  background: #1f7788 !important;
  display: block !important;
}

.input {
  border-right: none;

  @media screen and (min-width: 996px) {
    border-right: 2px solid #fff !important;

    &:last-of-type {
      border-right: none !important;
    }
  }
}

.destinationInput {
  width: 100% !important;

  input {
    &::placeholder {
      font-size: 13px !important;
    }
  }

  @media screen and (min-width: 996px) {
    min-width: 250px !important;
    @media screen and (min-width: 1280px) {
      min-width: 270px !important;
      border-right: 2px solid #fff !important;
      width: unset !important;

      input {
        &::placeholder {
          font-size: 16px !important;
        }
      }
    }
  }
}

.label {
  color: white !important;
  font-size: 20px !important;
}

.mobile {
  display: inline-flex !important;

  @media screen and (min-width: 1280px) {
    display: none !important;
  }
}

.desktop {
  display: none !important;

  @media screen and (min-width: 1280px) {
    display: inline-flex !important;
  }
}
