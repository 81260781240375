@use "src/assets/styles/variables";

.container {
  position: relative;
  height: 100%;
  min-height: calc(100vh - 100px);
  padding-top: 50px;

  * {
    scrollbar-width: auto !important;
    scrollbar-color: #ffffff #595eb6 !important;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px !important;
    height: 4px !important;
  }

  *::-webkit-scrollbar-track {
    background: #595eb6 !important;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #ffffff !important;
    border-radius: 3px !important;
    border: 0 solid #ffb8b8 !important;
  }
}

.containerFiltersOpen {
  padding-top: 280px;
}

.floatingButtons {
  position: fixed;
  bottom: 72px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  z-index: 9999999;
}

.buttonIcon {
  * {
    fill: #FF8552 !important;
  }
}

.sectionButton {
  border: 3px solid #fff !important;
  background-color: #474CAE !important;
  color: #fff !important;
  font-family: "Chillax-Semibold" !important;
  text-transform: none !important;
  border-radius: 16px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 8px !important;
}

.offers {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 200px;
  gap: 16px;
  padding: 0 16px 16px;


  @media screen and (min-width: variables.$media_s) {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-auto-rows: minmax(100px, 250px);
    gap: 32px;
  }

  @media screen and (min-width: variables.$media_xl) {
    grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
  }
}

.map {
  width: 100%;
  position: fixed;
  bottom: 0;
  top: 150px;
  left: 0;
  overflow: hidden;

  &::after {
    content: "";
    width: 100%;
    height: 2px;
    background: #000;
    position: absolute;
    left: 0;
    top: 0;
    filter: blur(6px);
    z-index: 9999999;
  }
}

.mapOpenFilters {
  top: 380px;
}

.weatherLoader {
  width: 100%;
  height: calc(100vh - 100px - 100px);
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
