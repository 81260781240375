.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-position: center;
  background-size: cover;
  gap: 100px;
}

.content {
  flex-grow: 1;
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  color: #fff !important;
  gap: 100px;
  text-align: center !important;

  @media screen and (min-width: 992px) {
    text-align: left !important;
  }
}

.verified {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.logo {
  display: none;

  @media screen and (min-width: 992px) {
    display: flex;
  }
}
