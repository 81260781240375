.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  background-color: #fff;
  border-radius: 20px;
}

.icon {
  width: 50px;
  height: 50px;
}

.watersportIcon {
  width: 80px;
  height: 80px;
}

.guests {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.guestsNumber {
  font-size: 32px !important;
}
