.container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  p {
    padding: 0;
    margin: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.detailsWrapper {
  font-family: "Chillax-semibold";
  color: #474cae;

  span {
    font-family: "Chillax-Medium";
  }
}

.guests {
  display: flex;
  align-items: center;
  gap: 8px;

  p {
    font-family: "Chillax-semibold";
    color: #474cae;
  }
}

.counter {
  display: grid;
  grid-template-columns: 30px 20px 40px;
  gap: 10px;

  p {
    font-family: "Chillax-semibold";
    color: #474cae;
    text-align: center;
    vertical-align: center;
    line-height: 30px;
  }
}

.button {
  background-color: #ff8552 !important;
  font-family: "Chillax-Medium" !important;
  color: #fff !important;
  border: 2px solid #fff !important;
  border-radius: 50% !important;
  height: 30px !important;
  width: 30px !important;
  min-width: unset !important;
  padding: 0 !important;
}

.services {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: "Chillax-semibold";
  color: #474cae;
}

.servicesWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}

.servicesList {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (min-width: 820px) {
    flex-direction: row;
  }
}
