@use 'src/assets/styles/variables';

.container {
  width: 100%;
  background-color: #474cae;
  border-radius: 20px;
  padding: 16px 8px 8px 8px;
  color: #fff !important;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 4px solid #fff !important;
  -webkit-box-shadow: 0 0 17px -11px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0 0 17px -11px rgba(66, 68, 90, 1);
  box-shadow: 0 0 17px -11px rgba(66, 68, 90, 1);
  overflow-y: auto;

  @media screen and (min-width: variables.$media_l) {
    padding: 30px 50px;
  }
}

.copy {
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center !important;
}

.priceSummary {
  font-size: 24px !important;
  font-family: 'Chillax-Semibold' !important;
  margin-left: 16px !important;

  span {
    color: #4BBBB7 !important;
  }

  @media screen and (min-width: variables.$media_l) {
    margin-left: 0 !important;
  }
}

.button {
  width: 45%;
  text-transform: none !important;
  color: #fff !important;
  border: 3px solid #fff !important;
  background-color: transparent !important;
  height: 50px !important;
  font-size: 20px !important;

  .button:disabled {
    background-color: tomato !important;
  }
}

.summary {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.bookButton {
  width: 100% !important;
  background-color: #4BBBB7 !important;
  -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 1) !important;
  -moz-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 1) !important;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 1) !important;
}

.bookButton:disabled {
  background-color: #474cae !important;
}

.summaryWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price {
  @extend .priceSummary;

  font-size: 16px !important;
}

.priceValue {
  @extend .price;

  text-align: right !important;
  font-size: 13px !important;
  font-family: 'Chillax-Medium' !important;
}
