@use 'src/assets/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  @media screen and (min-width: variables.$media_l) {
    display: grid;
    grid-template-columns: 60% 20%;
    gap: 16px;
  }
}

.textFieldWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.label {
  font-family: 'Chillax-semibold' !important;
  font-size: 22px !important;
  color: #474CAE !important;
}

.select {
  font-family: 'Chillax-Medium' !important;
  color: #474CAE !important;
  background-color: #fff !important;
  border: 1px solid #ddd !important;
  border-radius: 6px !important;
}

.popup {
  z-index: 99999999999999 !important;

  * {
    color: #474CAE !important;
    font-family: 'Chillax-Medium' !important;
  }
}
