@use 'src/assets/styles/variables';

.container {
  width: max-content;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4px;
  margin: 0 auto;

  @media screen and (min-width: variables.$media_l) {
    flex-direction: row;
    gap: 32px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  align-items: center;
  inline-size: 300px;
  overflow-wrap: break-word;

  @media screen and (min-width: variables.$media_l) {
    align-items: flex-start;
    overflow-wrap: unset !important;
    inline-size: unset !important;
  }
}

.text {
  color: #474CAE !important;
  font-size: 22px !important;
  font-family: 'Chillax-bold' !important;
  width: 100% !important;
  text-align: center !important;

  @media screen and (min-width: 380px) {
    font-size: 28px !important;
  }

  @media screen and (min-width: variables.$media_l) {
    font-size: 32px !important;
    text-align: left !important;
  }
}

.textSmall {
  @extend .text;

  font-size: 12px !important;

  @media screen and (min-width: 380px) {
    font-size: 16px !important;
  }

  @media screen and (min-width: variables.$media_l) {
    font-size: 22px !important;
  }
}

.button {
  border-radius: 40px !important;
  width: max-content !important;
  color: #fff !important;
  text-transform: none !important;
  font-family: "Chillax-semibold" !important;
  font-size: 18px !important;
  background-color: #FF9A70 !important;
  padding: 10px 20px !important;
  -webkit-box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25) !important;
  -moz-box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25) !important;
  box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25) !important;

  @media screen and (min-width: variables.$media_l) {
    padding: 10px 30px !important;
    margin: 0 auto !important;
  }
}

.icon {
  width: 180px;
  height: 180px;
  transform: translateX(-13px);

  @media screen and (min-width: 380px) {
    width: 240px;
    height: 240px;
    transform: translateX(-26px);
  }

  @media screen and (min-width: variables.$media_l) {
    width: 240px;
    height: 240px;
    transform: translateX(0);
  }
}
