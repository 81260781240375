.e-event-popup {
    background-color: #474CAE;
    padding: 16px;
    overflow-y: auto;
}

.e-quick-popup-wrapper {
    border-radius: 16px !important;
    overflow: hidden !important;
}

.e-popup-content {
    padding: 8px !important;
}

.e-appointment {
    padding: 0 !important;
    position: relative;
    border-radius: 12px !important;
}

.e-tbar-btn-text {
    font-family: 'Chillax-Semibold' !important;
    color: #474CAE !important;
}

.e-work-cells {
    background-color: #fff !important;
    border-color: #474CAE !important;
}

.e-alternate-cells {
    border-bottom: none !important;
}

.e-header-cells {
    color: #474CAE !important;
    font-family: 'Chillax-Semibold' !important;
    border: none !important;
    border-bottom: 1px solid #474CAE !important;
    text-align: center !important;
    font-size: 16px !important;
}

.e-date-header {
    color: #474CAE !important;
    font-family: 'Chillax-Semibold' !important;
}

.e-current-date > .e-date-header.e-navigate {
    background: #F58B74 !important;
    opacity: 1 !important;
    color: #fff !important;
}

.e-header-cells {
    text-align: center !important;
    width: 100% !important;
}

.e-more-indicator {
    color: #474CAE !important;
    font-family: 'Chillax-Semibold' !important;
}

.e-all-day-cells {
    border-color: #474CAE !important;
}

.e-time-slots {
    color: #474CAE !important;
    font-family: 'Chillax-Semibold' !important;
    border-color: #474CAE !important;
}

.e-current-time {
    font-family: 'Chillax-Semibold' !important;
    color: #474CAE !important;
}

.e-previous-timeline, .e-current-timeline {
    border-color: #474CAE !important;
}

.e-m-day, .e-m-date {
    color: #474CAE !important;
    font-family: 'Chillax-Semibold' !important;
}

.e-btn-icon.e-icon-down-arrow.e-icons.e-icon-right {
    color: #474CAE !important;
}

.e-btn-icon.e-icon-next.e-icons {
    color: #474CAE !important;
}

.e-btn-icon.e-icon-prev.e-icons {
    color: #474CAE !important;
}

.e-header-calendar.e-control.e-calendar.e-lib.e-keyboard > * {
    font-family: 'Chillax-Semibold' !important;
}

.e-day {
    color: #474CAE !important;
}

.e-week-header > tr > th {
    color: #5b62d5 !important;
}

.e-selected.e-focused-date.e-today > span {
    background-color: #F58B74 !important;
    color: #fff !important;
    border-color: #F58B74 !important;
}

.e-btn.e-today.e-flat.e-primary.e-css {
    color: #F58B74 !important;
    font-family: 'Chillax-Semibold' !important;
}

.e-date-icon-prev.e-icons {
    color: #474CAE !important;
}

.e-date-icon-next.e-icons {
    color: #474CAE !important;
}

.MuiTypography-root.MuiTypography-body1.css-1t9bmn5-MuiTypography-root {
    /*padding-left: 16px !important;*/
}

.e-popup-down-icon.e-icons {
    color: #474CAE !important;
}

.e-btn-icon.e-icons.e-icon-left {
    color: #474CAE !important;
}

.e-btn-icon.e-icon-agenda.e-icons.e-icon-left {
    color: #474CAE !important;
}

.e-active-view > button {
    background-color: #F58B74 !important;
}

.e-active-view > button > span {
    color: #fff !important;
}

.e-cell.e-selected > span {
    color: #fff !important;
    background-color: #F58B74 !important;
}

.e-header-cells.e-disable-dates {
    height: 62px !important;
}

.e-toolbar-items.e-tbar-pos {
    background-color: #fff !important;
    box-shadow: none !important;
}

.e-schedule-toolbar.e-control.e-toolbar.e-lib.e-toolpop.e-keyboard {
    box-shadow: none !important;
}

.e-tbar-btn.e-tbtn-txt.e-control.e-btn.e-lib {
    background: #fff !important;
}

.e-toolbar-item.e-views.e-active-view > button > span {
    background: #F58B74 !important;
    color: #fff !important;
    border-radius: 4px !important;
}

.e-tbar-btn.e-control.e-btn.e-lib.e-icon-btn {
    background: #fff !important;
}

.e-toolbar-item.e-schedule-seperator.e-separator {
    background-color: #474CAE !important;
}

.e-work-cells .e-more-indicator {
    /*display: none !important;*/
}

