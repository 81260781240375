.container {
display: flex;
  flex-direction: column;
  gap: 4px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.detailsWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.guestsDetailsButton {
  background-color: #fff; // Example blue color
  color: #474CAE; // White text
  padding: 8px 16px; // Padding
  border-color: #474CAE;
  border-radius: 4px; // Rounded corners
  display: flex; // To align icon and text
  align-items: center; // Center items vertically
  justify-content: center; // Center items horizontally

  .anticon {
    margin-right: 8px;
  }

  &:hover {
    background-color: darken(#fff, 10%);
  }
}


.policy {
  margin-top: 24px;

  p {
    font-family: 'Chillax-regular';
    color: #474CAE;
    font-size: 14px;
  }
}


.link {
  font-family: 'Chillax-regular';
  color: #474CAE;
  font-size: 14px;
  text-decoration: underline !important;

  &:hover {
    color: #474CAE !important;
  }
}