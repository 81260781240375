@use "src/assets/styles/variables";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  @media screen and (min-width: variables.$media_l) {
    padding: 8px;
    gap: 8px;
  }
}

.name {
  font-family: "Chillax-Semibold" !important;
  font-size: 18px !important;
  color: #fff !important;
}

.box {
  background-color: #fff !important;
  width: 100px !important;
  height: 100px !important;
  border-radius: 16px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: 1px solid #474CAE !important;
}

.icon {
  width: 50px;
  height: 50px;
}
