.container {
  display: flex;
  justify-content: center;
  align-items: center;

  * {
    scrollbar-width: auto !important;
    scrollbar-color: #ffffff #595eb6 !important;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px !important;
    height: 4px !important;
  }

  *::-webkit-scrollbar-track {
    background: #595eb6 !important;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #ffffff !important;
    border-radius: 3px !important;
    border: 0 solid #ffb8b8 !important;
  }
}

.container {
  * {
    scrollbar-width: auto !important;
    scrollbar-color: #ffffff #4BBBB7 !important;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px !important;
    height: 4px !important;
  }

  *::-webkit-scrollbar-track {
    background: #4BBBB7 !important;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #ffffff !important;
    border-radius: 3px !important;
    border: 0 solid #ffb8b8 !important;
  }
}

.menuIconGreen {
  * {
    fill: #4BBBB7 !important;
  }

  svg {
    width: 30px !important;
    height: 40px !important;
  }
}

.menuIconPurple {
  * {
    fill: #474CAE !important;
  }

  svg {
    width: 30px !important;
    height: 40px !important;
  }
}

.mobileMenu {
  width: 100%;
  height: calc(100vh - 100px);
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #FFF8F2;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.linksWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.link {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Chillax-bold' !important;
}


.section {
  height: 100%;
  padding-bottom: 16px;
  display: grid;
  grid-template-rows: 60px 1fr;
  gap: 32px;
  overflow-y: auto;

  * {
    scrollbar-width: auto !important;
    scrollbar-color: #ffffff #474CAE !important;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px !important;
    height: 4px !important;
  }

  *::-webkit-scrollbar-track {
    background: #474CAE !important;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #ffffff !important;
    border-radius: 3px !important;
    border: 0 solid #ffb8b8 !important;
  }
}

.sectionGreen {
  * {
    scrollbar-width: auto !important;
    scrollbar-color: #ffffff #4BBBB7 !important;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px !important;
    height: 4px !important;
  }

  *::-webkit-scrollbar-track {
    background: #4BBBB7 !important;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #ffffff !important;
    border-radius: 3px !important;
    border: 0 solid #ffb8b8 !important;
  }
}

.sectionButtons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background: #DEDEDE;
  border-radius: 32px;
}

.sectionButton {
  background-color: #DEDEDE !important;
  border-radius: 32px !important;
  font-family: 'Chillax-bold' !important;
  font-size: 14px !important;
}

.sectionButtonActive {
  background-color: #FFF8F2 !important;
  -webkit-box-shadow: 0px 0px 17px -14px rgba(0, 0, 0, 1) !important;
  -moz-box-shadow: 0px 0px 17px -14px rgba(0, 0, 0, 1) !important;
  box-shadow: 0px 0px 17px -14px rgba(0, 0, 0, 1) !important;
}

.sectionContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 50px;

  * {
    scrollbar-width: auto !important;
    scrollbar-color: #ffffff #474CAE !important;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px !important;
    height: 4px !important;
  }

  *::-webkit-scrollbar-track {
    background: #474CAE !important;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #ffffff !important;
    border-radius: 3px !important;
    border: 0 solid #ffb8b8 !important;
  }
}

.mobileMenuItem {
  width: 100%;
  display: grid !important;
  grid-template-columns: 100px 1fr;
  align-items: center;
  padding: 8px;

  img {
    width: 100%;
    height: 60px;
  }
}

.linkWatersportIcon {
  width: 40px !important;
  height: 40px !important;
  justify-self: center !important;
  align-self: center !important;
  transform: translateX(-20%) !important;
}

.globeIcon {
  width: 50px !important;
  height: 50px !important;
  justify-self: center !important;
  align-self: center !important;
}

.contentLink {
  font-family: 'Chillax-Medium' !important;
}

.contentLinkAll {
  @extend .contentLink;

}

.itemImg {
  width: 100%;
  height: 60px;
}

.watersportIcon {
  width: 50px;
  height: 50px;

  svg {
    width: 80px;
    height: 50px;
  }

  * {
    fill: #474CAE !important;
  }
}

.watersportGreenIcon {
  svg {
    * {
      fill: #4BBBB7 !important;
    }
  }
}
