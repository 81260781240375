@use 'src/assets/styles/variables';

.container {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;

  @media screen and (min-width: variables.$media_l) {
    height: 700px;
  }
}

.waveTop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.waveBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.gallery {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
}

.imagesWrapper {
  width: 110%;
  transform: translateX(-10%);
  height: 200%;
  display: grid;

  img {
    width: 100%;
    height: 100%;
    min-width: 150px;
    min-height: 150px;
    object-fit: cover;
  }

  @media screen and (min-width: variables.$media_l) {
    img {
      min-width: 200px;
      min-height: 200px;
    }
  }
}

