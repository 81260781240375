@use "src/assets/styles/variables";

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #F8F2ED;
}

.formWrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 80px 1fr 100px;
  gap: 20px;
  grid-template-columns: 1fr;
  overflow: hidden;

  * {
    scrollbar-width: auto !important;
    scrollbar-color: #ffffff #595eb6 !important;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px !important;
    height: 4px !important;
  }

  *::-webkit-scrollbar-track {
    background: #595eb6 !important;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #ffffff !important;
    border-radius: 3px !important;
    border: 0 solid #ffb8b8 !important;
  }


  @media screen and (min-width: variables.$media_l) {
    grid-template-rows: 1fr 100px;
    grid-template-columns: 120px 1fr;
  }
}

.formWrapperMenuOpen {
  grid-template-rows: 1fr 100px;

  @media screen and (min-width: variables.$media_l) {
    grid-template-columns: 500px 1fr;
    grid-template-rows: 1fr 100px;
  }
}

.progress {
  @media screen and (min-width: variables.$media_l) {
    grid-column: 1/3;
  }
}

.step {
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: auto;
  justify-content: center;
  padding: 0 20px;

  @media screen and (min-width: variables.$media_l) {
    display: flex;
    height: 90%;
    align-self: center;
  }
}

.stepMenuOpen {
  display: none;

  @media screen and (min-width: variables.$media_l) {
    display: flex;
    height: 90%;
    align-self: center;
    min-width: 1000px;
  }
}

.itemContainer {
  width: 100%;
  min-height: calc(100% - 10px);
  height: max-content;
  margin-bottom: 10px;
  display: grid;
  grid-template-rows: 1fr;
  padding: 20px;
  background-color: #474CAE;
  border-radius: 20px;
  border: 3px solid #fff;

  @media screen and (min-width: variables.$media_l) {
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.header {
  font-family: "Tanohe-Sans" !important;
  font-weight: bold !important;
  color: #fff !important;
  text-transform: uppercase !important;
  font-size: 20px !important;
  margin-left: 16px !important;
}

.mobile {
  @media screen and (min-width: variables.$media_l) {
    display: none;
  }
}

.desktop {
  display: none;

  @media screen and (min-width: variables.$media_l) {
    display: unset;
  }
}

.loader {
  position: absolute;
}
