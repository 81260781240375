.calendar {
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  width: 100% !important;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: 'Chillax-semibold', sans-serif;
  color: #474CAE;

  span {
    font-size: 16px;
    font-weight: bold;
  }

  .button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #474CAE;

    &:hover {
      opacity: 0.8;
    }
  }
}

.dayName {
  font-size: 12px;
  text-align: center;
  margin-bottom: 5px;
  color: #474CAE;
  font-family: 'Chillax-semibold';
}

.month {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.day {
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  height: 32px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-family: 'Chillax-regular', sans-serif;
  color: #474CAE;

  &:hover {
    background-color: #e0e0e0;
  }

  &.selected, &.inRange {
    background-color: #474CAE;
    color: #fff;
  }

  &.highlighted {
    background-color: #c5cae9;
    color: #fff;
  }

  &.blocked {
    background-color: #e0e0e0;
    cursor: not-allowed;
  }
}

@media only screen and (max-width: 540px) {
  .calendarBody {
    font-size: 14px;
  }

  .day {
    padding: 8px;
  }

  .header span {
    font-size: 14px;
  }
}

.calendarBody {
  display: flex;
  flex-direction: column;
  gap: 25px;

  span {
    font-family: 'Chillax-semibold', sans-serif;
    color: #474CAE;
      font-size: 16px;
      font-weight: bold;
    margin: 0 auto;
  }
}

.skeleton {
  background-color: #e0e0e0; /* Jasnoszary tło */
  color: transparent; /* Ukrycie tekstu */
  position: relative;
  overflow: hidden;
}

/* Animacja ładowania */
@keyframes loading {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

.skeleton::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.3), transparent);
  animation: loading 1.5s infinite;
}

.monthName {
  font-size: 16px !important;
}