@use "src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0 20px;

  @media screen and (min-width: variables.$media_xl) {
    max-width: 90%;
  }
}

.title {
  font-family: "Chillax-Medium" !important;
  font-size: 18px !important;
  margin-left: 20px !important;
}

.detailBox {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-auto-rows: 100px;
  align-items: center;
  row-gap: 20px;
  text-align: center !important;

  @media screen and (min-width: variables.$media_s) {
    row-gap: 40px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  @media screen and (min-width: variables.$media_xl) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 200px));
  }
}

.detailContent {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: flex-start;

  :first-child {
    color: #474cae !important;
    font-size: 22px;
    font-family: "Chillax-Semibold" !important;
  }

  :nth-child(3) {
    font-size: 12px !important;
    margin-top: -4px;
  }

  svg {
    width: 50px !important;
    height: 50px !important;
    margin-bottom: 5px;
  }
}
