@use 'src/assets/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-top: 16px;
}

.yourPayments {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 8px;
}

.bookings {
  color: #474CAE !important;
  font-size: 24px !important;
  font-family: 'Chillax-Medium' !important;
}


.paymentsSectionWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.sectionName {
  font-family: 'Chillax-Medium' !important;
  color: #474CAE !important;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #FF8552;
}
