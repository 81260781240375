@use "src/assets/styles/variables";

.container {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100vw;

  @media screen and (min-width: 1500px) {
    width: 100vw;
    margin: 0 auto;
    justify-content: space-between;
    max-width: 1500px;
    grid-column: 1/3;
  }
}

.button {
  border: 4px solid #474CAE !important;
  height: 50px;
  width: 40%;
  color: #474CAE !important;
  border-radius: 12px !important;
  text-transform: none !important;
  font-family: 'Chillax-Medium', sans-serif !important;
  font-size: 20px !important;

  @media screen and (min-width: variables.$media_l) {
    width: 250px !important;
  }
}

.nextButton {
  color: #fff !important;
  background-color: #FF8552 !important;
  border-color: #fff !important;
}

.button:disabled {
  background-color: tomato !important;
}

.modal {
  max-width: 450px !important;
  background-color: #F8F2ED !important;
}
