@use 'src/assets/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 16px;

  p {
    color: #474CAE !important;
    font-size: 28px !important;
    font-family: 'Chillax-semibold' !important;
  }
}

.closeIcon {
  width: 40px !important;
  height: 40px !important;
}

.selectWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    color: #474CAE !important;
    font-family: 'Chillax-semibold' !important;
  }
}

.popup {
  z-index: 99999999999 !important;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (min-width: variables.$media_l) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 32px;
    row-gap: 16px;
  }
}

.select {
  width: 100% !important;
  min-width: 180px !important;

  * {
    color: #474CAE !important;
    font-family: 'Chillax-Medium' !important;
  }
}

.saveButton {
  width: 150px !important;
  color: #fff !important;
  background-color: #FF8552 !important;
  border: 3px solid #fff !important;
  font-family: 'Chillax-semibold' !important;
  align-self: center !important;
  text-transform: none !important;
}

.datePicker {
  height: 100%;
  width: 100%;
  color: #474CAE;
  font-family: 'Chillax-Medium';
  padding-left: 8px;
  border: none;

  border: 1px solid #aaa;
  border-radius: 4px;
}
