.container {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  position: relative;
}

.burgerIcon {
  svg {
    width: 30px !important;
    height: 40px !important;
  }
}

.menuContainer {
  position: absolute;
  top: 180%;
  left: 50%;
  overflow: hidden;
  transform: translateX(-50%);
  width: max-content;
  background-color: #FFF8F3;
  padding: 8px 16px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  -webkit-box-shadow: 8px 8px 15px -12px rgba(66, 68, 90, 1);
  -moz-box-shadow: 8px 8px 15px -12px rgba(66, 68, 90, 1);
  box-shadow: 8px 8px 15px -12px rgba(66, 68, 90, 1);
  z-index: 999999999999999999999999999999999 !important;
}

.link {
  font-family: 'Chillax-semibold' !important;
  transition: background-color 0.3s !important;
  width: 100% !important;
  padding: 8px !important;
  border-radius: 8px !important;
  font-size: 16px !important;
}

.link:hover {
  background-color: #f1ece8 !important;
}

.logOutButton {
  font-family: 'Chillax-semibold' !important;
  width: 100% !important;
  text-align: left !important;
  text-transform: none !important;
  display: flex;
  justify-content: flex-start !important;
}
