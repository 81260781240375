.container {
  width: 100%;
  height: 50px;
  position: fixed;
  top: 100px;
  left: 0;
  display: grid;
  grid-template-rows: 1fr 50px;
  background-color: #F8EDE5;
}

.containerOpen {
  height: 275px;
}

.openButton {
  width: 100% !important;
  height: 50px !important;
  border-radius: 0 !important;
  color: #474CAE !important;
  transition: transform 0.3s !important;
}


.openButtonIcon {
  transition: transform 0.3s !important;
  transform: rotate(0) !important;
}

.openButtonIconOpen {
  transform: rotate(-180deg) !important;
}

.wrapper {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  justify-content: center;
}

.itemsContainer {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 30px 1fr 30px;
  width: max-content;
  max-width: 100vw;
  justify-self: center;
}

.itemElement {
  height: 100% !important;
  width: 100% !important;
  overflow: hidden !important;
  display: grid !important;
  grid-template-rows: 60px 1fr !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
}

.itemElementActive {
  p {
    color: #FF8552 !important;
  }

  * {
    fill: #FF8552 !important;
  }
}

.innerWrapper {
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
}

.itemIcon {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 50px;
    width: 50px;
  }
}

.itemName {
  text-align: center !important;
  font-size: 12px !important;
  color: #474CAE !important;
  font-family: 'Chillax-semibold' !important;
}


.arrowIcon {
  width: 100% !important;
  border-radius: 0 !important;
  height: 100% !important;
  align-self: center !important;
  justify-self: center !important;
  color: #474CAE !important;

  &:disabled {
    color: #999999 !important;
  }
}
