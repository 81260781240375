.container {
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-template-rows: 150px;
  gap: 20px;
  color: #474CAE !important;
  max-width: 500px;
  margin: 0 auto;
  border-radius: 10px;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
  }

  @media screen and (min-width: 500px) {
    grid-template-columns: 250px 1fr;
  }
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}
