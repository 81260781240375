@use "src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  overflow: hidden;
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.offersWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-height: 400px;
  overflow-y: scroll;
  padding: 0 32px;

  @media screen and (min-width: variables.$media_l) {
    height: calc(100% - 50px);
    max-height: unset;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

.button {
  width: 100% !important;
  height: 80% !important;
  max-height: 140px !important;
  background: #fff !important;
  border-radius: 24px !important;
  min-height: 140px !important;
  border: 1px solid #474CAE !important;
}

.title {
  font-family: 'Chillax-Semibold' !important;
  color: #fff !important;
  font-size: 18px !important;
  text-align: center !important;

  @media screen and (min-width: variables.$media_l) {
    text-align: left !important;
  }
}
