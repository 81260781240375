@use "src/assets/styles/variables";

.container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: variables.$media_l) {
    display: grid;
    grid-template-columns: 0.40fr 1fr;
    gap: 32px;
  }
}


.content {
  background-color: #595eb6;
  width: 100%;
  height: 100%;
  padding: 16px;
}

.contentLeft {
  @extend .content;

  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  padding-top: 32px;

  @media screen and (min-width: variables.$media_l) {
    border-radius: 16px;
    padding: 16px;
  }
}

.contentRight {
  @extend .content;

  display: flex;
  flex-direction: column;
  gap: 32px;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  padding-top: 32px;
  padding-bottom: 32px;

  @media screen and (min-width: variables.$media_l) {
    border-radius: 16px;
    padding: 16px 32px;
    gap: 16px;
  }
}

.detailsWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.bold {
  font-family: 'Chillax-semibold' !important;
  color: #fff !important;
  font-size: 18px !important;
}

.boldError {
  color: #ff3a3a !important;
}

.regular {
  font-family: 'Chillax-regular' !important;
  color: #fff !important;
}

.button {
  width: 150px !important;
  height: 55px !important;
  border-radius: 12px !important;
  background-color: #009B99 !important;
  border: 3px solid #fff !important;
  font-family: 'Chillax-Regular' !important;
  color: #fff !important;
  text-transform: none !important;
}

.buttonOrange {
  @extend .button;

  background-color: #FF8552 !important;
}

.detailContent {
  font-family: 'Chillax-regular' !important;
  color: #fff !important;
  font-size: 14px !important;

  span {
    font-family: 'Chillax-semibold' !important;
  }
}
