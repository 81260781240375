.container {
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(213, 146, 103, 1);
  border-bottom: 1px solid rgba(213, 146, 103, 1);

  @media screen and (min-width: 992px) {
    border: 1px solid rgba(213, 146, 103, 1);
    border-radius: 20px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  color: white !important;
  width: 80%;
  padding: 20px 0;

  @media screen and (min-width: 992px) {
    gap: 30px;
  }
}

.prices {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  text-align: center !important;
}

.buttons {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
}

.button {
  background-color: rgba(0, 0, 0, 0.5) !important;
  border: 1px solid rgba(213, 146, 103, 1) !important;
  color: white !important;
  text-transform: none !important;
}

.bookButton {
  grid-column: 1/3 !important;
  background: linear-gradient(121.49deg, #cac59b 7.09%, #d59267 61.5%, #ec2a00 134.79%),
    linear-gradient(0deg, #000000, #000000) !important;
  margin: 0 auto !important;
  width: 50% !important;
  border-radius: 30px !important;
  color: white !important;
}

.summary {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  text-align: center !important;
}

.separator {
  width: 100%;
  height: 1px;
  background: linear-gradient(95.09deg, #cac59b 12.84%, #d59267 67.6%, #ec2a00 138.88%);
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center !important;
}

.pricesValues {
  color: rgba(213, 146, 103, 1) !important;
  font-size: 20px !important;
}

.servicesValues {
  text-decoration: underline !important;
  font-weight: 300 !important;
}
