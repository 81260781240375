.searchBar {
  border-left: 2px solid #fff;
}

.link {
  background-color: #01ac8e;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.search {
  height: 30px;
  transition: 0.3s;

  &:hover {
    -webkit-filter: invert(1);
    filter: invert(1);
    transition: 0.3s;
  }
}
