.container {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #474CAE;
  display: grid;
  grid-template-columns: 1fr 30px;
}

.offerTitle {
  font-family: 'Chillax-Medium' !important;
  color: #0D1A1A !important;
}

.openContent {
  grid-column: 1/3;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.actionWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.actionName {
  font-size: 14px !important;
  font-family: 'Chillax-Medium' !important;
  color: #474CAE !important;
}

.actionContent {
  font-size: 14px !important;
  font-family: 'Chillax-Medium' !important;
  color: #0D1A1A !important;
}

.iconButton {
  width: 40px !important;
  height: 40px !important;
}

.iconButton:disabled {
  * {
    fill: #6d6d6d !important;
  }
}

.availabilityButton {
  border-radius: 10px !important;
  background-color: #FF8552 !important;
  border: 3px solid #fff !important;
  text-transform: none !important;
  color: #fff !important;
  width: 200px !important;
  font-family: 'Chillax-semibold' !important;
  margin-top: 12px !important;
}

.availabilityButton:disabled {
  background-color: #a9a9a9 !important;
}
