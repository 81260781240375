.container {
display: flex;
  gap: 4px;
}

.selectedSlots {
  font-family: 'Chillax-semibold';
  color: #474CAE;
  margin: 0;
  padding: 0;
}

.dates {
  font-family: 'Chillax-medium';
  color: #474CAE;
  margin: 0;
  padding: 0;
}