@use "src/assets/styles/variables";

.profile {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 20px;
  border: 3px solid #474CAE;
  border-radius: 20px;
  color: #474CAE !important;
  -webkit-box-shadow: 8px 8px 24px -15px rgba(66, 68, 90, 1);
  -moz-box-shadow: 8px 8px 24px -15px rgba(66, 68, 90, 1);
  box-shadow: 8px 8px 24px -15px rgba(66, 68, 90, 1);
  background-color: #FFF8F2;
  height: max-content;
}

.author {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 20px;

  img {
    border-radius: 50%;
    margin: 0 auto;
  }

  h1 {
    font-family: "Chillax-semibold" !important;
    font-size: 22px !important;
  }

  h3 {
    font-family: "Chillax-Regular" !important;
    font-size: 22px !important;

    span {
      font-family: 'Chillax-Medium' !important;
    }
  }
}

.description {
  padding: 0 20px;

  h2 {
    font-family: "Chillax-Regular" !important;
    font-size: 18px !important;
  }
}

.separator {
  height: 1px;
  width: 100%;
  background-color: #474CAE;
}