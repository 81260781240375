@use 'src/assets/styles/variables';

.slotPicker {
  display: grid;
  grid-template-columns: 1fr 30%;
  gap: 16px;

  @media screen and (min-width: variables.$media_l) {
    grid-template-columns: 1fr 150px;

  }
  }

.daysHeader {
  width: 100%;
  display: grid;
  grid-template-columns: 30px 1fr 30px;
}

.days {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;

  @media screen and (min-width: variables.$media_l) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

.slotsHeader {
  display: flex;
  align-items: center;
  width: 100%;

  p {
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 0;
    color: #474CAE;

    span {
      font-family: 'Chillax-Semibold';
    }
  }
}

.slotsBody {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 300px;
  gap: 4px;
  overflow-y: auto;
    padding-right: 16px;
}

.slotsSkeleton {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  padding: 16px;
}

.slotSkeleton {
  border-radius: 8px;
  width: 100% !important;
  margin: 0;
  padding: 0;
}

.dayButton {
  color: #474CAE;
}

.slotButton {
  color: #474CAE;
  display: flex;
  width: 100%;
  height: max-content;
  justify-content: center;
  align-items: center;

  span {
    font-size: 12px;
    margin: 0;
    padding: 0;
  }

  @media screen and (min-width: variables.$media_l) {
    span {
      font-size: 14px;
    }
  }
  }

.slotButton:hover {
  color: #474CAE !important;
  border-color: #474CAE !important;
}

.selected {
  background-color: #474CAE;
  color: #fff;
}

.activeDay {
  background-color: #474CAE;
  color: #fff;
}

.noAvailableSlotsDiv {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 0;
    color: #474CAE;
    font-family: 'Chillax-regular';
  }
}
