.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.button {
  background-color: #fff !important;
  border-radius: 16px !important;
  width: 120px !important;
  height: 120px !important;
}

.noOffers {
  font-family: 'Chillax-Medium' !important;
  font-size: 16px !important;
  color: #fff !important;
  text-align: center !important;
}

.modal {
  width: 100% !important;
  height: 300px !important;
}
