@use "src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
}

.title {
  width: 100%;
  text-align: center;

  @media screen and (min-width: variables.$media_l) {
    text-align: left;
  }
}

.input {
  fieldset {
    border: none !important;
  }
}

.button {
  width: 150px;
  height: 40px;
  align-self: center;
  background: linear-gradient(110.85deg, #cac59b 3.56%, #d59267 74.65%, rgba(236, 42, 0, 0.81) 103.93%),
    linear-gradient(0deg, #0d1a1a, #0d1a1a);

  @media screen and (min-width: variables.$media_l) {
    align-self: flex-end;
  }
}

.policy {
  span {
    color: #d59267;
  }
}

.separator {
  width: 100%;
  text-align: center;
  line-height: 0.1em !important;
  margin: 20px 0 !important;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    height: 1px;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 40%;
    background-color: #6b5b54;
  }

  &::before {
    content: "";
    position: absolute;
    height: 1px;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 40%;
    background-color: #6b5b54;
  }

  span {
    color: #fff;
  }
}
