.container {
  display: flex;
  gap: 8px;
  justify-content: space-evenly;
  align-items: center;
}

.menuItem {
  color: #474CAE !important;
  font-size: 14px !important;
  font-family: 'Chillax-regular' !important;
  border-radius: 10px !important;
  transition: background-color 0.3s, color 0.3s;
}

.activeMenuItem {
  background-color: #474CAE !important;
  color: #fff !important;
}
