@use "src/assets/styles/variables";

.container {
  gap: 4px;
  margin-bottom: 36px;
  cursor: pointer;
}

.content {
  width: 100%;
  flex-direction: column;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: 220px;
  justify-content: space-between;
  grid-template-rows: unset;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 42px;

  @media screen and (min-width: variables.$media_l) {
    gap: 12px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    justify-content: space-between;
  }

  @media screen and (min-width: variables.$media_l) and (min-height: 700px) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    justify-content: space-between;
    grid-template-rows: unset;
  }
}

.subTitle {
  font-family: 'Tanohe-Sans' !important;
  font-size: 28px !important;
  text-transform: uppercase !important;
}

.itemWrapper {
  height: 100% !important;
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 8px !important;
  padding: 0 !important;
  margin: 0 !important;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
  }

  p {
    text-align: left !important;
    width: 100% !important;
    color: #515C72 !important;
    font-family: 'Chillax-Medium' !important;
    text-decoration: underline !important;
  }
}
