@use "src/assets/styles/variables";

.containerMobile {
  width: 100%;
  height: 100%;
  background-color: #F8EDE5;
}

.containerMobileOpen {
  display: grid;
  grid-template-rows: 100px 1fr;
}

.activeSection {
  text-align: center !important;
  width: 100% !important;
  font-family: "Tanohe-Sans" !important;
  color: #474CAE !important;
  font-size: 24px !important;
  text-transform: uppercase !important;
}

.mobileHeader {
  width: 90%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 50px;
  align-items: center;
  padding: 0 8px;

  p {
    text-align: center !important;
  }
}

.mobileMenu {
  grid-row: 2/3;
  height: 100%;
  width: 100%;
  max-height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  list-style: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.sectionItem {
  display: grid;
  grid-template-columns: 80px 1fr;
  cursor: pointer !important;
  text-transform: capitalize !important;
  width: 70% !important;
  gap: 24px !important;

  p {
    font-family: 'Chillax-Medium' !important;
    color: #000 !important;
  }

  @media screen and (min-width: variables.$media_l) {
    flex-direction: column !important;
    display: flex !important;
    gap: 8px !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100px !important;
  }
}

.icon {
  width: 80px;
  height: 80px;
}

.iconActive {
  * {
    fill: #FF8552 !important;
  }
}

.sectionItemActive {

}

.dot {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #FF8552;
  background-color: #fff;
}

.dotActive {
  background-color: #FF8552;
}


.container {
  width: 100%;
  height: 80%;
  background-color: #fff;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 32px;
  border-bottom-right-radius: 32px;
  overflow-x: hidden;
  overflow-y: auto;

  * {
    scrollbar-width: auto;
    scrollbar-color: #474cae #595eb6;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 16px;
  }

  *::-webkit-scrollbar-track {
    background: #595eb6;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #474cae;
    border-radius: 0;
    border: 0 solid #595eb6;
  }
}

.iconDesktop {
  transform: rotate(-90deg) !important;
  transition: transform 0.3s !important;
}

.iconOpenDesktop {
  grid-row: 1/3;
  grid-column: 2/3;
  transform: rotate(90deg) !important;
}

.menu {
  width: 100%;
  height: 100%;
  list-style: none !important;
  margin: 0 !important;
  padding: 0 !important;
  padding: 16px !important;
  overflow-y: auto !important;
}
