.map {
    width: 100%;
    height: 100%;
    min-height: 350px;
    position: relative;
}

.mapLoader {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999999999 !important;
}
