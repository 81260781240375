.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: center;
  -webkit-box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25);
  box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25);
  background-color: rgba(255, 238, 221, 0.3);
  border-radius: 20px;
  cursor: pointer;
  color: #000 !important;
  transition: box-shadow 0.4s;
  min-height: 200px;

  &:hover {
    -webkit-box-shadow: 0 0 13px -4px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0 0 13px -4px rgba(66, 68, 90, 1);
    box-shadow: 0 0 13px -4px rgba(66, 68, 90, 1);
  }
}

.icon {
  height: 100px;
  width: 100%;
}
