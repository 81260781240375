@use 'src/assets/styles/variables';

.container {
  border: 4px solid #fff;
  border-left: none;
  border-right: none;
  padding: 8px 16px;
  gap: 8px;
  background-color: #474CAE;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: variables.$media_l) {
    border: 4px solid #fff;
    border-radius: 16px;
    width: max-content;
    height: 80%;
    align-self: center;
    overflow-y: auto;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 32px;
  }
}

.imageBox {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.imageButton {
  display: none !important;

  @media screen and (min-width: variables.$media_l) {
    display: block !important;
    color: #fff !important;
    font-family: 'Chillax-Light' !important;
    font-size: 14px !important;
    text-decoration: underline !important;
    text-transform: none !important;
  }
}

.image {
  border-radius: 50%;
  overflow: hidden;
  background: #000;
  width: 50px;
  height: 50px;

  @media screen and (min-width: variables.$media_l) {
    width: 120px;
    height: 120px;
  }
}

.data {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;

  :first-child {
    font-family: 'Chillax-semibold' !important;
    font-size: 14px !important;
    color: #fff !important;
  }

  :nth-child(2) {
    font-family: 'Chillax-regular' !important;
    color: #fff !important;
    text-decoration: underline !important;
    font-size: 12px !important;
  }

  :last-child {
    font-family: 'Chillax-regular' !important;
    color: #fff !important;
    font-size: 12px !important;
  }

  @media screen and (min-width: variables.$media_l) {
    gap: 8px;

    :first-child {
      font-size: 22px !important;
    }

    :nth-child(2) {
      font-size: 18px !important;
    }

    :last-child {
      font-size: 18px !important;
    }

  }
}

.menu {
  height: 300px;
}

.iconButton {
  display: flex !important;
  flex-direction: column !important;
  gap: 4px !important;
  color: #fff !important;
  background-color: #FF8552 !important;
  width: 50px !important;
  height: 50px !important;
  font-size: 12px !important;
}

.separator {
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.3);
}

.loader {
  @media screen and (min-width: variables.$media_l) {
    height: 100%;
  }
}

.changePicture {
  color: #fff !important;
  font-family: 'Chillax-Medium' !important;
  font-size: 14px !important;
  text-decoration: underline !important;
}

.profilePicture {
  padding: 5px;
  border-radius: 50%;
}

.avatar {
  width: 80px !important;
  height: 80px !important;
  border-radius: 50%;
  -webkit-box-shadow: inset 0 0 24px -5px rgba(66, 68, 90, 1);
  -moz-box-shadow: inset 0 0 24px -5px rgba(66, 68, 90, 1);
  box-shadow: inset 0 0 24px -5px rgba(66, 68, 90, 1);

  @media screen and (min-width: variables.$media_l) {
    width: 100px !important;
    height: 100px !important;
  }
}
