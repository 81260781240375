.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sectionName {
  font-size: 24px !important;
  font-family: 'Chillax-semibold' !important;
  color: #474CAE !important;
  margin: 0 auto !important;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.map {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 16px 32px;

  p {
    align-self: center !important;
    justify-self: center !important;
    font-family: 'Chillax-semibold' !important;
    font-size: 20px !important;
    color: #474CAE !important;
  }
}
