@use "src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 100vh;
  font-family: 'Chillax-Regular' !important;
}

.loader {
  height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (min-width: variables.$media_l) {
    padding: 0 5vw 0 10vw;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (min-width: variables.$media_xl) {
    display: grid;
    grid-template-columns: 1fr 400px;
    gap: 120px;
  }
}

.article {
  img {
    width: 100%;
  }
}

.text {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  img {
    width: calc(100% + 40px);
    margin: 20px -20px;
    border-radius: 20px;
    -webkit-box-shadow: 0 10px 9px -8px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0 10px 9px -8px rgba(66, 68, 90, 1);
    box-shadow: 0 10px 9px -8px rgba(66, 68, 90, 1);
  }

  h2 {
    font-family: 'Chillax-semibold' !important;
    font-size: 22px !important;
  }

  h3 {
    font-family: 'Chillax-semibold' !important;
    font-size: 20px !important;
  }

  h5 {
    font-family: 'Chillax-semibold' !important;
    font-size: 16px !important;
  }
}

.mobile {
  @media screen and (min-width: variables.$media_xl) {
    display: none !important;
  }
}

.desktop {
  display: none !important;

  @media screen and (min-width: variables.$media_xl) {
    display: flex !important;
  }
}
