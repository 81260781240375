@use "src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #595EB6;
  padding: 16px;
  border-radius: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.teamHours {
  color: #fff !important;
  font-family: 'Chillax-semibold' !important;
}

.hours {
  color: #FF8552 !important;
  font-size: 20px !important;
  font-family: 'Chillax-bold' !important;
}

.content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
}

.seeAllButton {
  text-decoration: underline !important;
  text-transform: none !important;
  color: #fff !important;
  font-family: 'Chillax-semibold' !important;
  border: none !important;
  width: max-content !important;
  align-self: flex-end !important;
}
