@use 'src/assets/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 16px;

  p {
    color: #474CAE !important;
    font-size: 36px !important;
    font-family: 'Chillax-semibold' !important;
  }
}

.closeIcon {
  width: 40px !important;
  height: 40px !important;
}

.content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 16px;
}

.data {
  display: none;

  @media screen and (min-width: variables.$media_l) {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 180px);

    p {
      color: #474CAE !important;
      font-size: 22px !important;
      font-family: 'Chillax-semibold' !important;
    }
  }
}

.center {
  text-align: center !important;
}

.serviceTitle {
  color: #474CAE !important;
  font-family: 'Chillax-semibold' !important;
}

.servicesWrapper {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: variables.$media_l) {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 180px);
  }
}

.popup {
  z-index: 9999999999999 !important;

  * {
    color: #474CAE !important;
    font-family: 'Chillax-Medium' !important;
  }
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #474CAE;
}

.select {
  max-height: 100px;
  overflow-y: auto;
  width: 100% !important;

  * {
    color: #474CAE !important;
    font-family: 'Chillax-Medium' !important;
  }
}

.icon {
  width: 40px !important;
  height: 40px !important;
  color: #474CAE !important;
}

.saveIcon {
  width: 32px !important;
  height: 32px !important;
  color: #474CAE !important;
  fill: #868ADA !important;
}
