@use "src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  background-color: #595EB6;
  padding: 16px;
  border-radius: 16px;
  width: 100%;
  gap: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 8px;
}
