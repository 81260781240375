@use "src/assets/styles/variables";

.container {
  min-height: 100vh;
  padding: 32px;
  background: rgb(14, 27, 69);
  background: linear-gradient(0deg, rgba(14, 27, 69, 1) 0%, rgba(76, 49, 42, 1) 49%, rgba(18, 80, 78, 1) 100%);
  display: grid;
  grid-template-rows: max-content 1fr;

  @media screen and (min-width: variables.$media_l) {
    grid-template-rows: unset;
    padding: 0;
    align-items: flex-start;
    display: grid;
    grid-template-columns: 1fr 650px;
  }

  @media only screen and (min-width: variables.$media_xl) {
    grid-template-columns: 1fr 750px;
  }

  @media screen and (min-width: variables.$media_xxl) {
    grid-template-columns: 1fr 850px;
  }
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 200px;
  padding: 50px;

  @media screen and (min-width: variables.$media_l) {
    display: none;
  }
}

.logoDesktop {
  display: none;

  @media screen and (min-width: variables.$media_l) {
    display: block;
    margin: auto;
  }
}

.title {
  display: none;

  @media screen and (min-width: variables.$media_l) {
    display: inline-block;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // gap: 50px;
  height: 100%;

  @media screen and (min-width: variables.$media_l) {
    background-color: #0d1919;
    height: 100%;
    padding: 32px;
    align-items: unset;
  }
}

.formWrapper {
  width: 90%;
  max-width: 500px;
  @media screen and (min-width: variables.$media_l) {
    max-width: unset;
    width: 70%;
    margin: 0 auto;
  }
}

.row {
  display: flex;
  width: max-content;
  justify-content: center;
  @media screen and (min-width: variables.$media_l) {
    justify-content: flex-start;
  }
}

.logo {
  height: 42px;
  margin-right: 10px;
}

.link {
  margin-left: 5px;

  &:hover {
    color: inherit;
  }
}
