@use "src/assets/styles/variables";

.container {
  @media screen and (min-width: variables.$media_l) {
    display: grid;
    gap: 20px;
    grid-auto-rows: 120px;
    position: relative;
    z-index: 50;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

    @media screen and (min-width: 1175px) {
      grid-auto-rows: 180px;
      gap: 10px;
    }
  }
}

.carouselContainer {
  border-radius: 16px;
  overflow: unset;
}

.carousel {
  display: flex;
}

.item {
  height: 200px;
  padding: 10px;
}

