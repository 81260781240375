@use "src/assets/styles/variables";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 13px;
  justify-content: center;
  align-items: center;
  min-height: 150px;

  * {
    color: #fff !important;
    text-align: center !important;
  }

  @media screen and (min-width: variables.$media_l) {
    min-height: unset;
  }
}
