@use "src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.header {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: flex-start;
  gap: 16px;
}

.text {
  width: max-content !important;
}

.photos {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 170px;
  gap: 16px;
}

.imagesWrapper {
  width: 100%;
  height: 140px;
  overflow-y: auto;
}

.iconButton {
  position: absolute !important;
  top: 8px !important;
  right: 8px !important;
  background-color: #fff !important;
  -webkit-box-shadow: 0 0 24px -15px rgba(66, 68, 90, 1) !important;
  -moz-box-shadow: 0 0 24px -15px rgba(66, 68, 90, 1) !important;
  box-shadow: 0 0 24px -15px rgba(66, 68, 90, 1) !important;

  & > * {
    color: #474CAE !important;
  }
}

.photosWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 16px;

  @media screen and (min-width: variables.$media_l) {
    display: grid;
    grid-auto-rows: auto;
  }
}

.photosWrapperEmpty {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 16px;

  @media screen and (min-width: variables.$media_l) {
    display: flex;
    gap: unset;
    align-items: center;
    overflow: hidden;
    justify-content: space-between;
  }
}

.imageContainer {
  display: flex !important;
  gap: 8px !important;
  position: relative !important;
  justify-self: center !important;
  width: 140px !important;
  height: 140px !important;
  margin: 0 auto !important;

  img {
    width: 100% !important;
    height: 100% !important;
    border-radius: 16px !important;
    object-fit: cover !important;
  }
}

.imageBackground {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 140px;
  height: 140px;
  border-radius: 16px;
  justify-self: center;

  svg {
    width: 80px;
    height: 80px;
  }
}

.tooltip {
  width: 30px !important;
  height: 30px !important;
  cursor: pointer !important;
}

.error {
  font-family: 'Comfortaa' !important;
  font-size: 0.75rem !important;
  width: 100% !important;
  margin-top: -8px !important;
  color: #f44336 !important;
  text-align: center !important;

  @media screen and (min-width: variables.$media_l) {
    text-align: left !important;
    padding-left: 8px !important;
  }
}

.button {
  width: 80px !important;
  height: 80px !important;
  background-color: #fff !important;
  border-radius: 20px !important;
  color: #595EB6 !important;
  font-size: 112px !important;
  font-family: 'Chillax-Semibold' !important;
  min-width: 80px !important;
}

