.calendar {
  margin: 0 auto;
  height: 300px;
  font-family: 'Roboto', sans-serif;
  width: 100% !important;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: 'Chillax-semibold', sans-serif;

  span {
    font-size: 16px;
    font-weight: bold;
    color: #474CAE;
  }

  .button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;
    color: #474CAE;

    &:hover {
      opacity: 0.8;
    }
  }
}

.weekdays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  font-family: 'Chillax-regular', sans-serif;
  font-size: 12px;
  gap: 4px;
  margin-bottom: 5px;
  width: 100%;
}

.calendarBody {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  flex-direction: column;
  gap: 4px;
}

.day {
  padding: 10px;
  width: 100%;
  text-align: center;
  border-radius: 5px;
  height: 32px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-family: 'Chillax-regular', sans-serif;
  color: #474CAE;

  &:hover {
    border-color: #474CAE !important;
    color: #474CAE !important;
  }

  &.selected, &.inRange {
    background-color: #3f51b5;
    color: #fff;
  }

  &.nonCurrentMonth {
    background-color: #f0f0f0;
    color: #d0d0d0;
  }

  &.blocked {
    background-color: #e0e0e0;
    cursor: not-allowed;
  }
}

.pastDay {
  background-color: #e0e0e0; // Jasny kolor tła
  color: #b0b0b0; // Szary tekst
  cursor: not-allowed;

  &:hover {
    background-color: #e0e0e0; // Zachowaj tło bez zmiany przy najechaniu
  }
}

@media only screen and (max-width: 540px) {
  .calendarBody {
    font-size: 14px;
  }

  .day {
    padding: 8px;
  }

  .header span {
    font-size: 14px;
  }
}

.skeleton {
  background-color: #e0e0e0;
  color: transparent;
  position: relative;
  overflow: hidden;
  height: 32px;
  border-radius: 5px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.3), transparent);
    animation: loading 1.5s infinite;
  }
}

@keyframes loading {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

.weekdayHeader {

  p {
    color: #474CAE;
    font-family: Chillax-semibold;
  }
}
