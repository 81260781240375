@use "src/assets/styles/variables";

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 42px 0;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
}

.name {
  color: variables.$footer_primary_color !important;
}

.button {
  & > * {
    fill: variables.$footer_primary_color !important;
  }
}
