.MuiInputBase-root::before {
    border-bottom: 1px solid #474CAE !important;
}

.MuiInputBase-root::after {
    border-bottom: 1px solid #474CAE !important;
}

.css-1e051er-MuiFormLabel-root-MuiInputLabel-root {
    color: #474CAE !important;
}
