.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  align-items: center;

  * {
    font-size: 12px !important;
  }
}

.header {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4px;
  align-items: center;

  p {
    font-family: 'Chillax-Semibold' !important;
    color: #595EB6 !important;
  }
}

.button {
  width: 40px !important;
  height: 40px !important;
  border-radius: 8px !important;
  margin-left: auto !important;
  color: #595EB6 !important;
  transition: transform 0.3s !important;
  transform: rotate(-180deg) !important;

  svg {
    width: 60px !important;
    height: 60px !important;
  }
}

.buttonOpen {
  transform: rotate(0) !important;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.contentItem {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  p {
    font-family: 'Chillax-Medium' !important;
    color: #595EB6 !important;
  }
}
