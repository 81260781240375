@use "src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (min-width: variables.$media_l) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 70% 1fr;
    height: 100%;
    position: relative;
    gap: 16px;
  }
}

.carouselWrapper {
  height: 100%;
  overflow: hidden;
  border: 3px solid #fff;
  border-radius: 16px;
}

.carouselItem {
  height: 55vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 3px solid #fff;
}

.description {
  width: 100%;
  background-color: #595EB6;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 16px;

  * {
    color: #fff !important;
  }

  :first-child {
    font-family: 'Chillax-semibold' !important;
    font-size: 18px !important;
  }

  @media screen and (min-width: variables.$media_l) {
    width: 65%;
    height: 100%;
  }
}

.details {
  border: 3px solid #fff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 16px;
  width: 100%;
  padding: 16px 24px;
  background-color: #595EB6;
  z-index: 999;

  @media screen and (min-width: variables.$media_l) {
    position: absolute;
    width: 33.5%;
    top: 65%;
    right: 0;
    min-width: 300px;
  }
}

.title {
  font-size: 24px !important;
  font-family: 'Chillax-semibold' !important;
  color: #fff !important;
}

.address {
  font-size: 14px !important;
  font-family: 'Chillax-Light' !important;
  color: #fff !important;
  transform: translateY(-6px);
}

.price {
  font-size: 28px !important;
  font-family: 'Chillax-semibold' !important;
  color: #fff !important;

  @media screen and (min-width: variables.$media_l) {
    font-size: 32px !important;
  }
}
