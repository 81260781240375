@use "src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;

  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto !important;
    scrollbar-color: #ffffff #595eb6 !important;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px !important;
    height: 4px !important;
  }

  *::-webkit-scrollbar-track {
    background: #595eb6 !important;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #ffffff !important;
    border-radius: 3px !important;
    border: 0 solid #ffb8b8 !important;
  }

  @media screen and (min-width: variables.$media_l) {
    display: grid;
    grid-template-columns: 250px 1fr;
    height: calc(100% - 24px);
  }
}

.servicesToAdd {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (min-width: variables.$media_l) {
    display: grid;
    height: 100%;
    grid-template-rows: repeat(4, 1fr);
    gap: 16px;
  }
}

.services {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;

  @media screen and (min-width: variables.$media_l) {
    display: grid;
    height: 100%;
    grid-template-rows: repeat(4, 1fr);
    gap: 16px;
  }
}

.servicesWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (min-width: variables.$media_l) {
    height: 100%;
    flex-direction: row;
    overflow-x: auto;
  }
}

.serviceItem {
  height: 100%;
  width: 200px;
  min-width: 200px;
  background-color: #595eb6;
  border-radius: 16px;
  padding: 16px 0;
}

.firstItem {
  width: 100% !important;
  color: #fff !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-family: 'Chillax-Semibold' !important;
  font-size: 22px !important;
}

.iconWrapper {
  display: none;

  @media screen and (min-width: variables.$media_l) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 16px;
  }
}

.logo {
  height: 80px;
  width: 80px;
}

.modal {
  width: 100%;
}
