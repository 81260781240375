.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.header {
  display: flex;
  gap: 16px;
  align-items: center;
}

.button {
  border: 2px solid #fff;
  font-family: 'Chillax-medium';
  background-color: #ff8552;
  color: #fff;
}

.button:hover {
  border: 2px solid #fff !important;
  font-family: 'Chillax-medium' !important;
  background-color: #ff8552 !important;
  color: #fff !important;
}

.open {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background-color: #f3f4f6;
  border-radius: 4px;
  border: 1px solid #e2e8f0;
}

.serviceDetail {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;

  p {
    margin: 0;
    font-size: 14px;
    font-family: 'Chillax-medium';
    color: #474CAE;
  }

  p span {
    font-weight: bold;
  }
}

.editButton {
  @extend .button;

  position: absolute;
  right: 8px;
  top: 8px;
}
