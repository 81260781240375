@use "src/assets/styles/variables";

.section {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  padding: 20px 0;

  h5 {
    font-family: 'Chillax-Medium' !important;
    font-size: 20px !important;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    font-family: 'Chillax-Regular' !important;
    font-size: 18px !important;
    margin-left: 20px !important;

    span {
      text-decoration: underline !important;
    }
  }
}

.otherArticles {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  a {
    & > * {
      font-family: 'Chillax-Regular' !important;
      font-size: 16px !important;
      text-decoration: underline !important;
      color: black !important;
    }
  }
}