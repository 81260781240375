@use "src/assets/styles/variables";

.container {
  width: 100%;
  background-color: #474CAE;
  min-height: 100%;
  padding: 16px;
  border-radius: 16px;
  border: 3px solid #fff;
  gap: 16px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  @media screen and (min-width: variables.$media_l) {
    display: grid;
    grid-template-columns: 250px 1fr 250px;
    height: 100%;
  }
}

.formBoxDesktop {
  display: none;

  @media screen and (min-width: variables.$media_l) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    overflow-y: auto;
  }
}

.item {
  width: 100%;
  height: max-content;
  background-color: #595EB6;
  border-radius: 24px;
  padding: 16px;
  min-height: 160px !important;


  @media screen and (min-width: variables.$media_l) {
    height: 100% !important;
  }
}

.avatar {
  width: 50px;
  height: 50px;
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 16px;
  left: 0;
}
