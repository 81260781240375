@use "src/assets/styles/variables";

.container {
  width: 100%;
  height: 100vh;
  max-height: 100vh;
}

.wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 100px 1fr 80px;
  padding: 0 16px;

  * {
    scrollbar-width: auto !important;
    scrollbar-color: #ffffff #595eb6 !important;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px !important;
    height: 4px !important;
  }

  *::-webkit-scrollbar-track {
    background: #595eb6 !important;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #ffffff !important;
    border-radius: 3px !important;
    border: 0 solid #ffb8b8 !important;
  }


  @media screen and (min-width: variables.$media_l) {
    grid-template-columns: 80px 1fr;
    grid-template-rows: 1fr 80px;
    overflow: hidden;
    column-gap: 64px;
    padding: 16px 16px 0 0;
  }
}

.wrapperOpenMenu {
  grid-template-rows: 1fr 80px;

  @media screen and (min-width: variables.$media_l) {
    grid-template-columns: 400px 1fr;
  }
}

.stepWrapper {
  width: 100%;
  height: 100%;
  background-color: #474CAE;
  padding: 16px;
  overflow-y: auto;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  * {
    scrollbar-width: auto !important;
    scrollbar-color: #ffffff #595eb6 !important;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px !important;
    height: 4px !important;
  }

  *::-webkit-scrollbar-track {
    background: #595eb6 !important;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #ffffff !important;
    border-radius: 3px !important;
    border: 0 solid #ffb8b8 !important;
  }


  @media screen and (min-width: variables.$media_l) {
    min-width: 800px;
    overflow-y: auto;
  }
}

.stepWrapperMenuOpen {
  display: none;

  @media screen and (min-width: variables.$media_l) {
    display: flex;
  }
}

.formStepName {
  font-family: "Tanohe-Sans" !important;
  color: #fff !important;
  font-weight: 800 !important;
  text-transform: uppercase !important;
  margin-left: 8px !important;
}

.itemWrapper {
  width: 100%;
  background-color: #595eb6;
  padding: 16px;
  border-radius: 24px;
  height: 100%;
}

.itemTitle {
  color: #fff !important;
  font-family: 'Chillax-Semibold' !important;
}

.itemTitleError {
  color: #ec3636 !important;
}
