.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
}

.modal {
  width: 100% !important;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.title {
  height: 100%;
  font-family: 'Chillax-semibold';
  font-size: 24px;
  color: #474CAE;
  text-align: center;
  width: 100%;
}

.closeButton {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border: 2px solid #fff;
  background-color: #474CAE;
  color: #fff;
}

.closeButton:hover {
  border: 2px solid #fff !important;
  background-color: #474CAE !important;
  color: #fff !important;
}
