@use "src/assets/styles/variables";

.container {
  background-color: #595EB6;
  display: flex;
  flex-direction: column;
  gap: 64px;
  width: 100%;
  padding: 16px 0;
  padding-left: 16px;
  position: relative;
  border-radius: 8px;

  @media screen and (min-width: variables.$media_l) {
    position: absolute;
    top: 16px;
    left: 16px;
    bottom: 16px;
    right: 16px;
    width: unset;
    overflow-y: auto;
    border-radius: 8px;
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-template-rows: 10px 1fr;
    row-gap: 0;
    gap: 32px;
  }
}

.header {
  width: 100%;
  position: relative;

  @media screen and (min-width: variables.$media_l) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.formDescription {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  padding: 0 16px;

  h4, h5 {
    color: #fff !important;
    text-align: justify !important;
  }

  h5 {
    font-family: "Chillax-Regular" !important;
    font-size: 18px !important;
  }

  h4 {
    font-family: 'Chillax-semibold' !important;
    font-size: 22px !important;
  }
}

.iconBox {
  width: 100%;
  display: flex;
  min-height: 200px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 16px;

  img {
    width: 100px;
    height: 100px;
  }
}

.closeForm {
  position: absolute !important;
  width: 30px !important;
  height: 30px !important;
  right: 8px !important;
  top: 0 !important;

  @media screen and (min-width: variables.$media_l) {
    top: 16px !important;
    right: 16px !important;
    width: 35px !important;
    height: 35px !important;
  }
}

.formWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  padding: 0 16px;
  max-width: 1200px;
}

.inputWrapper {
  width: 100%;
  position: relative;
}

.label {
  margin-left: 8px !important;
  color: #fff !important;
  font-family: "Chillax-Medium" !important;
  font-size: 20px !important;
  margin-bottom: 8px !important;
}

.error {
  position: absolute !important;
  left: 8px !important;
  bottom: 0 !important;
  font-size: 12px !important;
}

.submit {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: variables.$media_l) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 16px;
  }
}

.submitButton {
  width: 150px !important;
  height: 50px !important;
  background-color: #FF8552 !important;
  color: #fff !important;
  font-family: 'Chillax-Medium' !important;
  font-size: 20px !important;
  border: 3px solid #fff !important;
  border-radius: 8px !important;
  align-self: center !important;
  text-transform: none !important;
}

.submitButton:disabled {
  background-color: #999998 !important;
}

.submitCopy {
  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
}

.copy {
  color: #fff !important;
  font-size: 16px !important;
  font-family: "Chillax-Regular" !important;
}

.link {
  @extend .copy;

  color: #FF8552 !important;
}

.title {
  font-size: 24px !important;
  font-family: 'Chillax-Semibold' !important;
  color: #fff !important;
  grid-column: 1/3 !important;
  grid-row: 1/2 !important;
  text-align: center !important;
}
