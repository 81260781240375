.container {
  width: 80%;
  min-height: 140px;
  background-color: #fff;
  display: grid;
  border-radius: 20px;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(2, 1fr);
  padding: 10px;
  margin-top: 20px;
  overflow: hidden;

  @media screen and (min-width: 450px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: 996px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: 1280px) {
    width: 80%;
  }
}

.partner {
  width: 150px;
  height: auto;
  margin: auto;
}
