.container {
  background-color: #fff;
  padding: 16px 8px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.image {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 2px solid #474CAE;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.bold {
  color: #2E3767 !important;
  font-size: 13px !important;
  font-family: 'Chillax-bold' !important;
}

.boldOrange {
  @extend .bold;

  color: #FF8552 !important;
}

.light {
  color: #595EB6 !important;
  font-size: 11px !important;
  font-family: 'Chillax-Medium' !important;
}

.iconButton {
  width: 25px !important;
  height: 25px !important;
  color: #CCCCCC !important;
  transition: transform 0.3s !important;
}

.iconButtonOpen {
  transform: rotate(-180deg) !important;
}
