@use "src/assets/styles/variables";

.crew {
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media screen and (min-width: variables.$media_l) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0 100px;
  }
}

.box {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  -webkit-box-shadow: 0 7px 16px -13px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0 7px 16px -13px rgba(66, 68, 90, 1);
  box-shadow: 0 7px 16px -13px rgba(66, 68, 90, 1);
  background-color: #FCF9F6;
  border-radius: 20px;
  max-width: 600px;
  align-self: center;

  @media screen and (min-width: variables.$media_l) {
    width: 100%;
    padding: 50px;
    height: 100%;
    max-width: 700px;
    max-height: 850px;
    justify-self: center;
  }
}

.boxHeader {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.images {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  div {
    display: flex;
    flex-direction: column;
    gap: 10px;

    h3 {
      font-family: "Chillax-Regular" !important;
      font-size: 16px !important;
    }

    h4 {
      font-family: 'Chillax-semibold' !important;
      font-size: 20px !important;
    }

    img {
      height: 100%;
    }
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }

  @media screen and (min-width: variables.$media_l) {
    gap: 50px;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
  }
}

.crewImages {
  img {
    width: 60%;
    margin: 20px auto;
  }
}

.image {
  border-radius: 15px;
  width: 100%;
  height: 100%;
  max-height: 550px;
}

.title {
  font-family: "Chillax-semibold" !important;
  font-size: 20px !important;

  @media screen and (min-width: variables.$media_l) {
    font-size: 64px !important;
  }
}

.content {
  font-family: 'Chillax-Regular' !important;
  font-size: 16px !important;
}
