.container {
  width: 100%;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  padding-bottom: 12px;
  overflow-y: auto;
  gap: 8px;
}

.button {
  background-color: #F6F6FB !important;
  color: #2B2F42 !important;
  border-radius: 40px !important;
  height: 55px !important;
  font-family: 'Chillax-bold' !important;
  transition: background-color 0.3s, color 0.3s;
  text-transform: capitalize !important;
  padding: 4px 12px !important;
}

.activeButton {
  background-color: #FF8D5E !important;
  color: #474CAE !important;
}
