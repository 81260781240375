@use "src/assets/styles/variables";

.container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  height: 98%;
}

.paymentItem {
  background-color: #595EB6;
  width: 100%;
  border-radius: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  gap: 32px;
  padding: 20px 0;
  height: 100% !important;

  @media screen and (min-width: variables.$media_l) {
    padding: 10px 15px;
  }
}

.stripeBox {
  border-radius: 16px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  min-width: 250px;
  height: 200px;

  @media screen and (min-width: variables.$media_l) {
    width: 350px;
    height: 250px;
  }
}

.texts {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 16px;

  * {
    color: #fff !important;
    font-family: "Chillax-Medium" !important;
    font-size: 18px !important;
  }

  h3 {
    font-family: "Chillax-Semibold" !important;
    font-size: 22px !important;
  }
}

.button {
  width: 200px !important;
  height: 55px !important;
  background-color: #FF8552 !important;
  border: 3px solid #fff !important;
  font-family: 'Chillax-Medium' !important;
  text-transform: none !important;
  color: #FFF !important;
  font-size: 20px !important;
  border-radius: 16px !important;
  margin-bottom: 8px !important;
}

.tooltip {
  position: absolute !important;
  bottom: 16px !important;
  right: 16px !important;
  width: 30px !important;
  height: 30px !important;
  cursor: pointer !important;
}
