.container {
  position: fixed !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  bottom: 0 !important;
  display: flex !important;
  background-color: #FFF8F2 !important;
  padding: 16px 32px !important;
  border-radius: 0 !important;
  border-top-right-radius: 16px !important;
  border-top-left-radius: 16px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 8px !important;
  -webkit-box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25) !important;
  -moz-box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25) !important;
  box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25) !important;
  cursor: pointer !important;
  z-index: 9999999999 !important;

  p {
    font-family: 'Chillax-semibold' !important;
    color: #474CAE !important;
  }

  svg {
    color: #474CAE !important;
  }
}

.isGreen {

  p {
    color: #4BBBB7 !important;
  }

  svg {
    color: #4BBBB7 !important;
  }
}
