@use "src/assets/styles/variables";

.container {
  height: 100%;
  background-color: #595eb6;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 250px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  padding: 24px 0;
  position: relative;

  p, h5 {
    color: #fff !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  h5 {
    font-family: 'Chillax-Semibold' !important;
    font-size: 20px !important;
    line-height: 10px !important;
  }

  p {
    font-family: "Chillax-regular" !important;
    font-size: 14px !important;
  }

  @media screen and (min-width: variables.$media_l) {
    max-height: 100%;
    justify-content: space-between;
    gap: 4px;
    padding: 20px 0;
  }
}

.button {
  width: 80px !important;
  height: 80px !important;
  min-width: unset !important;
  background-color: #fff !important;
  border-radius: 20px !important;
  color: #595EB6 !important;
  font-size: 64px !important;
  font-family: 'Chillax-Semibold' !important;
}

.tooltip {
  position: absolute !important;
  right: 8px !important;
  bottom: 8px !important;
}