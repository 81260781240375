@use "src/assets/styles/variables";

.hero {
  height: 100vh;
  width: 100%;
  background: rgb(255, 163, 133);
  background: linear-gradient(0deg, rgba(255, 163, 133, 1) 35%, rgba(255, 228, 195, 1) 100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative !important;
}

.heroContent {
  background-color: #F8EDE5;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: max-content;
  position: relative;

  @media screen and (min-width: variables.$media_s) {
    background-color: transparent;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 250px;
      bottom: -30px;
      left: 0;
      background-color: #F8EDE5;
    }
  }
}

.wave {
  bottom: 90%;
  position: absolute;

  @media screen and (min-width: variables.$media_s) {
    bottom: 0;
    width: 100vw;

    &::after {
      content: '';
      background-color: #000;
      width: 300px;
      z-index: 9999999;
      height: 300px;
      bottom: 0;
      left: 0;
    }
  }
}

.sun {
  position: absolute;
  right: -30%;
  top: -108%;
  height: 400px;
  width: 400px;

  @media screen and (min-width: variables.$media_s) {
    top: -85%;
    height: 400px;
    width: 400px;
    right: 0;
  }

  @media screen and (min-width: variables.$media_xl) {
    width: 600px;
    height: 600px;
    top: -100%;
  }

  @media screen and (min-width: variables.$media_hd) {
    width: 900px;
    height: 900px;
    top: -160%;
  }
}

.heroText {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  z-index: 99999;
  transform: translateY(-30%);

  @media screen and (min-width: variables.$media_s) {
    width: 40vw;
    margin-left: 0;
    min-width: 600px;
  }

  @media screen and (min-width: variables.$media_l) {
    width: 40vw;
    margin-left: 10vw;
    min-width: 600px;
  }
}

.title {
  font-family: 'Chillax-semibold' !important;
  font-size: 48px !important;

  @media screen and (min-width: variables.$media_s) {
    font-size: 84px !important;
  }

  @media screen and (min-width: variables.$media_l) {
    font-size: 96px !important;
  }

  @media screen and (min-width: variables.$media_hd) {
    font-size: 128px !important;
  }
}


.content {
  font-family: 'Chillax-Medium' !important;
  font-size: 18px !important;
  line-height: 24px !important;
}

.desktop {
  display: none;

  @media screen and (min-width: variables.$media_s) {
    display: block;
  }
}

.mobile {
  @media screen and (min-width: variables.$media_s) {
    display: none;
  }
}