.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
  width: 100% !important;
  padding: 16px 0;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.button {
  border: 2px solid #fff;
  background-color: #FF8552;
  color: #fff;
  font-family: 'Chillax-medium';
  min-width: 80px;
}

.button:hover {
  border: 2px solid #fff !important;
  background-color: #FF8552 !important;
  color: #fff !important;
  font-family: 'Chillax-medium' !important;
}

.input {
  width: 100% !important;
}

.price {

}
