.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.wrapper {
  width: 100%;
  height: 140px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 24px;
  position: relative;
  overflow: hidden;
  border: 1px solid #474CAE;
}

.watersport {
  position: absolute;
  top: 0;
  left: 0;
  background: #474CAE;
  width: 120px;
  height: 80px;
  -webkit-clip-path: ellipse(40% 40% at 20% 17%);
  clip-path: ellipse(40% 40% at 20% 17%);
  padding-left: 12px;

  img {
    width: 40px;
    height: 40px;
  }
}

.buttons {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #474CAE;
  width: 120px;
  height: 80px;
  -webkit-clip-path: ellipse(40% 40% at 80% 77%);
  clip-path: ellipse(40% 40% at 80% 77%);
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 14px;
  padding-bottom: 4px;
}

.dotsIcon {
  color: #fff !important;
  text-align: center !important;
  width: 40px !important;
  height: 40px !important;
  min-width: unset !important;
}

.title {
  font-size: 18px !important;
  color: #fff !important;
  font-family: "Chillax-Medium" !important;
  text-align: center !important;
  cursor: pointer !important;
}

@keyframes showMenu {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes closeMenu {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.menu {
  width: 100%;
  height: 100%;
  background: #474CAE;
  opacity: 0;
  animation: showMenu 0.3s both;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(3, 1fr);
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.menuItem {
  width: 40%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  color: #fff !important;
  cursor: pointer;

  p {
    font-family: 'Chillax-Semibold' !important;
    text-transform: capitalize !important;
  }
}

.closeMenuButton {
  position: absolute !important;
  width: 30px !important;
  height: 30px !important;
  right: 8px !important;
  top: 8px !important;
  color: #fff !important;
}

.icon {
  width: 40px !important;
  height: 40px !important;

  svg {
    width: 40px !important;
    height: 40px !important;
  }

  * {
    fill: #fff !important;
  }
}
