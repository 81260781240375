.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #FF8552;
  padding: 16px 0;
}

.itemInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;

  :first-child, :last-child {
    color: #474CAE !important;
  }

  :first-child {
    font-family: 'Chillax-regular' !important;
    font-size: 14px !important;
  }

  :last-child {
    font-family: 'Chillax-semibold' !important;
    font-size: 16px !important;
  }
}

.itemButton {
  font-family: 'Chillax-regular' !important;
  color: #474CAE !important;
  text-decoration: underline !important;
  font-size: 14px !important;
}
