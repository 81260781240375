.profile {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.tooltip {
  position: absolute !important;
  bottom: 16px !important;
  right: 16px !important;
  width: 30px !important;
  height: 30px !important;
  cursor: pointer !important;
}

.text {
  text-align: center !important;
}

.profilePicture {
  padding: 5px;
  border-radius: 50%;
}

.avatar {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50%;
  -webkit-box-shadow: inset 0 0 24px -5px rgba(66, 68, 90, 1);
  -moz-box-shadow: inset 0 0 24px -5px rgba(66, 68, 90, 1);
  box-shadow: inset 0 0 24px -5px rgba(66, 68, 90, 1);
}

.empty {
  width: 100px !important;
  height: 100px !important;
}
