@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-navigations/styles/material.css';

@font-face {
    font-family: Bontang;
    src: url("./assets/fonts/bontang.woff") format("woff");
    src: url("./assets/fonts/bontang.woff2") format("woff2");
    font-style: normal;
}

@font-face {
    font-family: 'Tanohe-Sans';
    src: url("./assets/fonts/tanoheSans-Bold.woff2") format("woff2"),
    url("./assets/fonts/tanoheSans-Bold.woff") format("woff");
    font-weight: 800;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Chillax-Light';
    src: url('assets/fonts/Chillax-Light.woff2') format('woff2'),
    url('assets/fonts/Chillax-Light.woff') format('woff'),
    url('assets/fonts/Chillax-Light.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Chillax-Regular';
    src: url('assets/fonts/Chillax-Regular.woff2') format('woff2'),
    url('assets/fonts/Chillax-Regular.woff') format('woff'),
    url('assets/fonts/Chillax-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Chillax-Medium';
    src: url('assets/fonts/Chillax-Medium.woff2') format('woff2'),
    url('assets/fonts/Chillax-Medium.woff') format('woff'),
    url('assets/fonts/Chillax-Medium.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Chillax-Semibold';
    src: url('assets/fonts/Chillax-Semibold.woff2') format('woff2'),
    url('assets/fonts/Chillax-Semibold.woff') format('woff'),
    url('assets/fonts/Chillax-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Chillax-Bold';
    src: url('assets/fonts/Chillax-Bold.woff2') format('woff2'),
    url('assets/fonts/Chillax-Bold.woff') format('woff'),
    url('assets/fonts/Chillax-Bold.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}