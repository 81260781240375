.container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.fieldWrapper {
  display: flex;
  flex-direction: column;

  p {
    margin-left: 8px !important;
    font-family: 'Chillax-Medium' !important;
    color: #474CAE !important;
  }
}

.input {
  font-family: 'Chillax-Medium' !important;
  color: #474CAE !important;
}

.select {
  @extend .input;

  background-color: #fff !important;
  border: 1px solid #ddd !important;
  border-radius: 6px !important;
}

.popup {
  z-index: 99999999999 !important;

  * {
    font-family: 'Chillax-Medium' !important;
    color: #474CAE !important;
  }
}
