.container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  padding: 16px 32px;
  border-bottom: 2px solid #474CAE;

  p {
    font-family: 'Chillax-Medium' !important;
  }
}

.selectWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;

  svg {
    width: 30px;
    height: 30px;
  }
}

.button {
  width: max-content !important;
  background-color: #FF8552 !important;
  justify-self: center !important;

  p {
    font-family: 'Chillax-semibold' !important;
    color: #fff !important;
  }
}

.button:disabled {
  background-color: #a9a9a9 !important;
}

.buttonIcon {
  color: #fff !important;
}

.iconButton {
  width: 50px !important;
  height: 50px !important;
  align-self: center !important;
  justify-self: center !important;
}

.iconButton:disabled {
  * {
    fill: #a4a4a4 !important;
  }
}
