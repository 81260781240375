.container {
  width: 100%;
  height: 90%;
  overflow-y: auto;
  background: #fff;
  padding: 16px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.grid {
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.3fr;
}

.details {
  @extend .grid;
}

.overview {
  @extend .grid;
}

.currentPackage {
  @extend .grid;
}

.lastBookings {
  @extend .grid;
}

.newClient {
  font-family: 'Chillax-bold' !important;
  text-transform: uppercase !important;
  color: #595EB6 !important;
}

.currentPackageLeft {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.name {
  text-align: center !important;
  color: #2E3767 !important;
  font-family: 'Chillax-bold' !important;
  font-size: 28px !important;
}

.time {
  width: 100%;
  display: flex;
  justify-content: space-between;

  p {
    font-family: 'Chillax-regular' !important;
    font-size: 14px !important;
  }

  :first-child {
    text-align: left !important;
  }

  :last-child {
    text-align: right !important;
  }
}

.progress {
  width: 100%;
  background: #DAD7FE;
  color: #fff;
  height: 8px;
  border-radius: 12px;
  overflow: hidden;

  div {
    width: 70%;
    background: #474CAE;
    height: 100%;
  }
}

.detailsWrapper {
  display: flex;
  align-items: center;
  gap: 6px;

  p {
    color: #595EB6 !important;
    font-size: 12px !important;
  }

  :first-child {
    font-family: 'Chillax-semibold' !important;
  }

  :last-child {
    font-family: 'Chillax-regular' !important;
  }
}

.detailsAbout {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.detailsWrapperNotes {
  display: flex;
  flex-direction: column;
  gap: 6px;

  :first-child {
    color: #595EB6 !important;
    font-family: 'Chillax-Medium' !important;
    font-size: 14px !important;
    text-transform: uppercase !important;
  }

  :last-child {
    font-size: 12px !important;
    font-family: 'Chillax-regular' !important;
    color: #595EB6 !important;
  }
}

.detailsName {
  text-transform: uppercase !important;
  font-family: 'Chillax-Medium' !important;
  font-size: 14px !important;
  color: #595EB6 !important;
}

.editButton {
  background-color: #FF8552 !important;
  color: #fff !important;
  text-transform: none !important;
  font-family: 'Chillax-Semibold' !important;
  border-radius: 16px !important;
  padding: 4px 20px !important;
  width: max-content !important;
}

.bookingWrapper {
  @extend .detailsWrapper;
}

.bold {
  color: #595EB6 !important;
  font-size: 12px !important;
  font-family: 'Chillax-semibold' !important;
}

.regular {
  @extend .bold;

  font-family: 'Chillax-regular' !important;
}
