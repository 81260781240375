@use "src/assets/styles/variables";

.moreArticles {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0 20px;
}

.sectionName {
  font-family: 'Tanohe-Sans' !important;
  font-size: 24px !important;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media screen and (min-width: variables.$media_m) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

}

.articleBox {
  display: flex;
  flex-direction: column;
  gap: 5px;

  h4, h3 {
    font-family: "Chillax-Regular" !important;
    padding: 0 20px !important;
    color: #1F1A16 !important;
  }

  h3 {
    font-size: 16px !important;
    line-height: 130% !important;
  }

  h4 {
    font-size: 14px !important;
  }

  img {
    width: 100%;
    height: 200px;
    border-radius: 20px;
    -webkit-box-shadow: 0 10px 9px -8px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0 10px 9px -8px rgba(66, 68, 90, 1);
    box-shadow: 0 10px 9px -8px rgba(66, 68, 90, 1);
    margin-bottom: 10px;
    object-fit: cover;
  }
}

.button {
  font-family: 'Comfortaa' !important;
  background-color: #FF8552 !important;
  color: #fff !important;
  border: 5px solid white !important;
  margin: 0 auto !important;
  width: 180px !important;
  height: 60px !important;
  border-radius: 18px !important;
  text-transform: none !important;
  font-size: 18px !important;
  -webkit-box-shadow: 0 0 17px -7px rgba(66, 68, 90, 1) !important;
  -moz-box-shadow: 0 0 17px -7px rgba(66, 68, 90, 1) !important;
  box-shadow: 0 0 17px -7px rgba(66, 68, 90, 1) !important;
}
