.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  padding: 20px;
  border-radius: 8px;
}

.headerTitle {
  font-family: "Chillax-semibold";
  color: #474cae;
  font-size: 24px;
  margin: 0;
  padding: 0;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
}

.label {
  color: #474cae;
  font-family: "Chillax-semibold";
  padding: 0;
  margin: 0;
}

.field {
  padding: 12px;
  font-family: "Chillax-regular", sans-serif;
  margin-bottom: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 16px;
  color: #474cae !important;
}

.field::placeholder {
  color: #888;
  font-family: 'Chillax-medium' !important;
}

.errorMessage {
  font-family: "Chillax-regular", sans-serif;
  font-size: 14px;
  color: #c82d2d;
  margin-top: 4px;
}

.field[style*="grid-column: 1/3"] {
  grid-column: 1 / -1;
}

.errorField {
  border-color: #c82d2d !important;
}
