@use "src/assets/styles/variables";

.container {
  background-color: #474CAE;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  border: 3px solid #fff;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;

  @media screen and (min-width: variables.$media_l) {
    overflow-y: unset;
    max-height: 100%;
  }
}

.insights {
  font-family: 'Tanohe-Sans' !important;
  font-size: 20px !important;
  color: #fff !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
}

.title {
  font-family: 'Chillax-bold' !important;
  color: #FF8552 !important;
  font-size: 26px !important;
  text-transform: uppercase !important;
}

.contentMobile {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (min-width: variables.$media_l) {
    display: none;
  }
}


.contentDesktop {
  display: none;

  @media screen and (min-width: variables.$media_l) {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    max-height: 100%;
    height: 100%;
    width: 100%;
    overflow: hidden;
    gap: 16px;
    padding: 0 16px;
  }
}

.leftSection {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.rightSection {
  width: 100%;
  height: 100%;
  gap: 8px;
  overflow-y: auto;
  display: grid;
  grid-template-rows: 80px 0.4fr 0.6fr;
}

