@use "src/assets/styles/variables";

.container {
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 16px;

  @media screen and (min-width: variables.$media_l) {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
}

.wrapper {
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;

  @media screen and (min-width: variables.$media_l) {
    width: 150px;
    height: 150px;
  }
}

.organizationName {
  font-family: "Chillax-Semibold" !important;
  color: #fff !important;

  @media screen and (min-width: variables.$media_l) {
    font-size: 20px !important;
  }
}

.organizationDescription {
  font-family: "Chillax-Regular" !important;
  color: #fff !important;
  font-size: 14px !important;
  padding-left: 3px !important;

  @media screen and (min-width: variables.$media_l) {
    font-size: 18px !important;
  }
}

.editButton {
  justify-self: flex-end !important;
  font-family: "Chillax-Regular" !important;
  text-decoration: underline !important;
  font-size: 14px !important;
  color: #FFF !important;
}

.contact {
  display: none;

  @media screen and (min-width: variables.$media_l) {
    display: flex;
  }
}

.contactDetails {
  font-family: "Chillax-Semibold" !important;
  color: #fff !important;

  @media screen and (min-width: variables.$media_l) {
    font-size: 20px !important;
  }
}

.separator {
  display: none;

  @media screen and (min-width: variables.$media_l) {
    display: block;
    height: 2px;
    width: 100%;
    background-color: rgb(255, 255, 255, 0.3);
  }
}

.loader {
  align-self: center;
  justify-self: center;
  grid-column: 1/3;
}

.contactData {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.contactText {
  color: #fff !important;
  font-size: 14px !important;
  font-family: 'Chillax-Regular' !important;
}
