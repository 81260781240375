@use "src/assets/styles/variables";

.container {
  border-radius: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  padding-left: 10%;
  padding-top: 5%;
  align-items: flex-start;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
  -webkit-box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25);
  box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25);
  position: relative;
  overflow: hidden;
  height: 100%;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  transition: transform .4s;
}

.container:hover .image {
  @media screen and (min-width: variables.$media_m) {
    transform: scale(1.1);
  }
}

.title {
  color: #fff !important;
  font-family: "Chillax-Semibold", sans-serif !important;
  font-size: 24px !important;
  z-index: 9999999;

  @media screen and (min-width: variables.$media_xl) {
    font-size: 36px !important;
  }
}
