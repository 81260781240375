.mobileContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.mobileContent {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 50px;
  gap: 8px;
  align-items: center;
}

.pictureWrapper {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #474CAE;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobilePicture {
  width: 60%;
  height: 60%;
}

.mobileName {
  font-family: 'Chillax-Medium' !important;
}

.mobileIcon {
  color: #474CAE !important;
}

.mobileIconOpen {
  transform: rotate(180deg);
}

.mobileDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.mobileRoleWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 50px 1fr;
  justify-content: space-between;
  gap: 8px;
}

.mobileRole {
  color: #474CAE !important;
  font-family: 'Chillax-Medium' !important;
}

.container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
}

.name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  position: relative;
}

.picture {
  width: 50px;
  object-fit: cover;
  border-radius: 50%;
  padding: 5px;
  border: 1px solid #474CAE;
  height: 50px;
}

.button {
  width: max-content !important;
}
