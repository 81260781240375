@use "src/assets/styles/variables";

.container {
  gap: 0;
}

.content {
  width: 100%;
}

.subTitle {
  font-family: 'Tanohe-Sans' !important;
  font-size: 28px !important;
  text-transform: uppercase !important;
}

.carousel {
  display: flex;
  justify-content: space-between;
}

.item {
  width: 100%;
  display: flex;
  height: 200px;
  justify-content: center;
  align-items: center;
}

.image {
  width: 150px;
  margin: 0 auto;
}
