@use "../../../../../assets/styles/variables";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
  overflow-y: auto;

  @media screen and (min-width: variables.$media_l) {
    flex-direction: row;
    gap: 32px;
  }
}

.leftSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (min-width: variables.$media_l) {
    width: max-content;
    gap: 32px;
  }
}

.guestsList {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.bookings {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  :first-child {
    font-family: 'Chillax-Semibold' !important;
    font-size: 20px !important;
    color: #fff !important;
  }

  :last-child {
    font-family: 'Chillax-Semibold' !important;
    font-size: 28px !important;
    color: #FF8552 !important;
  }
}

.rightSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (min-width: variables.$media_l) {
    flex-grow: 1;
    gap: 32px;
  }
}

.buttons {
  display: flex;
  align-content: center;
  gap: 16px;
  overflow-x: auto;

  @media screen and (min-width: variables.$media_l) {
    overflow-x: unset;
  }
}

.button {
  background-color: #F6F6FB !important;
  color: #2B2F42 !important;
  text-transform: none !important;
  border-radius: 40px !important;
  height: 60px !important;
  font-family: 'Chillax-bold' !important;
  transition: background-color 0.3s, color 0.3s;
}
