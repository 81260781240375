@use "src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  color: #474CAE !important;
  padding-top: 80px;
  margin-bottom: 60px;

  @media screen and (min-width: variables.$media_xxl) and (min-height: 940px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 140vh;
    min-height: 140vh;
    align-items: flex-start;
    padding: 15vh 60px 0;
    position: relative;
    gap: 20vw;
  }
}

.background {
  display: none;

  @media screen and (min-width: variables.$media_xxl) and (min-height: 940px) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

.aboutUsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  overflow-y: auto;
  max-height: 100vh;

  @media screen and (min-width: variables.$media_xxl) and (min-height: 940px) {
    justify-self: flex-start;
    width: 75%;
    z-index: 9999;
  }
}

.aboutUsContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contentWrapper {
  width: 100%;
  display: flex;
  background-color: #FCF9F6;
  padding: 20px;
  align-items: flex-start;
  gap: 20px;
  border-radius: 12px;

  div {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}


.title {
  font-family: 'Tanohe-Sans' !important;
  font-size: 36px !important;
  text-transform: uppercase !important;

  @media screen and (min-width: variables.$media_xxl) and (min-height: 940px) {
    font-size: 75px !important;
  }
}

.text {
  font-family: 'Chillax-Medium' !important;
  font-size: 16px !important;

  span {
    font-family: 'Chillax-semibold' !important;
  }

  @media screen and (min-width: variables.$media_xxl) and (min-height: 940px) {
    font-size: 18px !important;
  }
}

.companyName {
  font-family: 'Chillax-semibold' !important;
}

.icon {
  width: 30px;
}

.desktop {
  display: none;

  @media screen and (min-width: variables.$media_xxl) and (min-height: 940px) {
    display: block;
  }
}
