@use "src/assets/styles/variables";

.header {
  height: 105vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-bottom-right-radius: 40px;
  border-bottom-left-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 5vh;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 20px;
  color: #fff !important;
  position: relative;

  @media screen and (min-width: variables.$media_l) {
    width: 600px;
    margin: 40px 10vw;
    gap: 20px;
  }
}

.title {
  font-family: "Chillax-semibold" !important;
  font-size: 24px !important;
  line-height: 130% !important;

  @media screen and (min-width: variables.$media_l) {
    font-size: 42px !important;
  }
}

.date {
  font-family: "Chillax-Regular" !important;
  font-size: 16px !important;
}

.authorSection {
  display: flex;
  gap: 20px;
}

.author {
  display: flex;
  flex-direction: column;
  gap: 5px;

  h3 {
    font-family: "Chillax-Regular" !important;
    font-size: 20px !important;
  }

  h4 {
    font-family: "Chillax-Light" !important;
    font-size: 14px !important;
  }
}

.profilePicture {
  height: 70px;
  width: 70px;
  border-radius: 50%;
}
