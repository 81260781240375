@use "src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #F8F2ED;
  position: absolute;
  top: 0;
  left: 0;
  gap: 40px;

  @media screen and (min-width: variables.$media_l) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0;
    max-height: 100vh;
    overflow: hidden;
  }
}

.header {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  width: max-content;
  cursor: pointer;
}

.logo {
  height: 50px;
  width: 50px;
  fill: #474CAE !important;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  gap: 50px;
}

.about {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: variables.$media_l) {
    width: 80%;
    align-self: center;
  }
}

.title {
  font-size: 26px !important;
  color: #474CAE !important;
  text-align: left !important;
  width: 100% !important;
  text-transform: uppercase !important;

  @media screen and (min-width: variables.$media_l) {
    font-size: 36px !important;
  }
}

.button {
  width: 200px !important;
  height: 50px !important;
  background-color: #FF8552 !important;
  border: 3px solid #FFF !important;
  color: #fff !important;
  text-transform: none !important;
}

.rules {
  background-color: #474CAE;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 3px solid #FFF;
  -webkit-box-shadow: 0 0 6px 0 rgba(66, 68, 90, 1);
  -moz-box-shadow: 0 0 6px 0 rgba(66, 68, 90, 1);
  box-shadow: 0 0 6px 0 rgba(66, 68, 90, 1);

  @media screen and (min-width: variables.$media_l) {
    border-top: none;
    border-left: 3px solid #FFF;
  }
}

.image {
  width: 100%;
  max-width: 500px;

  @media screen and (min-width: variables.$media_l) {
    max-width: unset;
    width: 80%;
    align-self: center;
  }
}