.container {
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 40px;
  background-color: #F8EDE5;
  color: #474CAE !important;
  padding-bottom: 40px;
}
