@use 'src/assets/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  @media screen and (min-width: variables.$media_l) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
  }
}

.textFieldWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.label {
  font-family: 'Chillax-semibold' !important;
  font-size: 22px !important;
  color: #474CAE !important;
}

.labelError {
  color: #df1212 !important;
}

.sendButton {
  font-family: 'Chillax-Medium' !important;
  font-size: 14px !important;
  align-self: center !important;
  background-color: #FF8552 !important;
  color: #fff !important;
  border: 3px solid #fff !important;
  border-radius: 12px !important;
  width: 180px !important;
  text-transform: none !important;
}

.sendButton:disabled {
  background-color: #808080 !important;
}
