.container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
}

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: flex-end;
}

.iconButton {
  display: grid !important;
  grid-template-rows: 40px 30px !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 16px !important;

  p {
    font-size: 14px !important;
    font-family: 'Chillax-Semibold' !important;
  }
}

.menuWrapper {
  -webkit-box-shadow: 8px 8px 15px -12px rgba(66, 68, 90, 1);
  -moz-box-shadow: 8px 8px 15px -12px rgba(66, 68, 90, 1);
  box-shadow: 8px 8px 15px -12px rgba(66, 68, 90, 1);
  width: 450px;
  height: 40vh;
  max-height: 50vh;
  background-color: #FFF8F2;
  position: absolute;
  top: 110px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 16px;
  padding: 16px;
  display: grid;
  grid-template-rows: 1fr;

  * {
    scrollbar-width: auto !important;
    scrollbar-color: #ffffff #595eb6 !important;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px !important;
    height: 4px !important;
  }

  *::-webkit-scrollbar-track {
    background: #595eb6 !important;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #ffffff !important;
    border-radius: 3px !important;
    border: 0 solid #ffb8b8 !important;
  }
}

.menuWrapperGreen {
  * {
    scrollbar-width: auto !important;
    scrollbar-color: #ffffff #4BBBB7 !important;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px !important;
    height: 4px !important;
  }

  *::-webkit-scrollbar-track {
    background: #4BBBB7 !important;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #ffffff !important;
    border-radius: 3px !important;
    border: 0 solid #ffb8b8 !important;
  }
}

.menuContent {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  overflow-y: scroll;

  img {
    width: 50px;
    height: 50px;
  }
}

.icon {
  width: 50px;
  height: 35px;
}

.allDestinations {
  width: 100%;
  gap: 16px;
}

.name {
  width: 100% !important;
  text-align: center !important;
  font-family: 'Chillax-Semibold' !important;
  font-size: 32px !important;
}

.mainIcon {
  width: 40px !important;
  height: 40px !important;
  align-self: center !important;
  margin: 0 auto !important;
}

.mainIconPurple {
  * {
    fill: #474CAE !important;
  }
}

.watersportIcon {
  width: 70px;
  height: 70px;

  * {
    fill: #474CAE !important;
  }
}

.allWatersports {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 40px !important;
    height: 40px !important;
  }
}


.watersportGreenIcon {
  * {
    fill: #4BBBB7 !important;
  }
}

.menuItem {
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.3s ease-in-out;
  border-radius: 16px;
  padding: 0 8px;
  height: 50px;
}

.menuItem:hover {
  background-color: #cacaca;
}

.itemName {
  font-family: 'Chillax-Semibold' !important;
}

.watersportIcon {
  width: 50px;
  height: 50px;

  svg {
    width: 50px;
    height: 50px;
  }

  * {
    fill: #474CAE !important;
  }
}

.watersportGreenIcon {
  svg {
    * {
      fill: #4BBBB7 !important;
    }
  }
}
