.container {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #F8EDE5 !important;
  min-height: 100vh;

}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 30px;
  max-width: 1400px;
  align-self: center;
}

.title {
  align-self: flex-start;
  color: #474CAEFF;
  font-family: "Chillax-Semibold", sans-serif;
  font-size: 24px;
}

.text {
  color: #474CAEFF;
  font-family: "Chillax-Regular", sans-serif;

  img {
    object-fit: contain;
    width: 100%;
  }
}