.container {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  gap: 16px;
  align-items: center;
}

.buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 8px 0;
  gap: 8px;
}

.open {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background-color: #f3f4f6;
  border-radius: 4px;
  border: 1px solid #e2e8f0;
}

.closed {
  display: none;
}

.guestDetail {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.editButton {
  position: absolute;
  right: 8px;
  top: 8px;
}
