@use "src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 940px;
  overflow: hidden;
  color: #fff !important;
  margin-bottom: -100px;
}

.background {
  height: 800px;
}

.wrapper {
  position: absolute;
  display: grid;
  width: 100%;
  grid-template-rows: 250px 1fr;
  height: 700px;
  padding: 55px 30px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  gap: 90px;

  @media screen and (min-width: variables.$media_s) {
    grid-template-rows: 250px 1fr;
  }

  @media screen and (min-width: 900px) {
    grid-template-rows: 280px 1fr;
    gap: 20px;
  }

  @media screen and (min-width: variables.$media_xl) {
    gap: 40px;
  }

  @media screen and (min-width: variables.$media_hd) {
    padding: 30px 30px;
    gap: 80px;
  }
}

.owner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.button {
  color: #fff !important;
  text-transform: none !important;
  border: 3px solid white !important;
  width: 150px !important;
}

.reviews {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  align-items: center;
  max-width: 1540px;
  margin: 0 auto;
}

.reviewsWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  @media screen and (min-width: variables.$media_xl) {
    justify-content: space-between;
  }
}

.reviewsTitle {
  width: 100% !important;
  text-align: left !important;
  font-size: 24px !important;
  font-family: 'Chillax-Medium' !important;
}

.navButton {
  background-color: transparent !important;
  border: none !important;
  color: #fff !important;
  font-size: 24px !important;
  cursor: pointer !important;
  padding: 10px !important;
  transition: color 0.3s ease !important;
  width: 20px !important;
  height: 20px !important;
  min-width: unset !important;
}
