@use "src/assets/styles/variables";

.container {
  height: 100%;
  padding: 16px;

  @media screen and (min-width: variables.$media_l) {
    height: 95%;
    padding: 8px 32px;
    gap: 0;
  }
}

.item {
  background-color: #595EB6;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  overflow-y: auto;
  padding: 16px;

  @media screen and (min-width: variables.$media_l) {
    padding: 16px 32px;
  }
}

.form {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;

  * {
    scrollbar-width: auto !important;
    scrollbar-color: #ffffff #595eb6 !important;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px !important;
    height: 4px !important;
  }

  *::-webkit-scrollbar-track {
    background: #595eb6 !important;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #ffffff !important;
    border-radius: 3px !important;
    border: 0 solid #ffb8b8 !important;
  }

}

.formHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  position: relative;
  width: 100%;
  padding: 10px;

  & > * {
    color: #474CAE !important;
  }
}

.formContent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
}

.icon {
  position: absolute !important;
  left: 0 !important;
  align-self: center !important;
}

.mapContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.text {
  color: #FFF !important;
  font-family: 'Chillax-Semibold' !important;
  font-size: 18px !important;
  line-height: 20px !important;
  width: 100% !important;

  @media screen and (min-width: variables.$media_l) {
    font-size: 20px !important;
  }
}

.map {
  width: 100%;
  height: 100%;
  border: 4px solid #fff;
  border-radius: 18px;
}

.emptyTag {
  color: #474CAE !important;
}
