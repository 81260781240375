@use 'src/assets/styles/variables';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border: 3px solid #474CAE;
  border-radius: 32px;
  position: relative;
  height: 50px;
  -webkit-box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25);
  box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25);
  overflow: hidden !important;

  * {
    scrollbar-width: auto !important;
    scrollbar-color: #ffffff #595eb6 !important;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px !important;
    height: 4px !important;
  }

  *::-webkit-scrollbar-track {
    background: #595eb6 !important;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #ffffff !important;
    border-radius: 3px !important;
    border: 0 solid #ffb8b8 !important;
  }

  @media screen and (min-width: variables.$media_l) {
    height: 55px;
  }
}

.containerGreen {
  border-color: #4BBBB7;

  * {
    scrollbar-width: auto !important;
    scrollbar-color: #ffffff #4BBBB7 !important;
    scroll-margin-right: 8px !important;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px !important;
    height: 4px !important;
  }

  *::-webkit-scrollbar-track {
    background: #4BBBB7 !important;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #ffffff !important;
    border-radius: 3px !important;
    border: 0 solid #ffb8b8 !important;
  }
}

.input {
  width: 100% !important;
  height: 100% !important;

  * {
    font-family: 'Chillax-semibold' !important;
    color: #474CAE !important;
  }
}

.inputGreen {
  * {
    color: #4BBBB7 !important;
  }
}

.popup {
  z-index: 9999999999999 !important;
  padding: 8px !important;
  border: 2px solid #474CAE;
  border-radius: 26px !important;
  -webkit-box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25) !important;
  -moz-box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25) !important;
  box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25) !important;
  background-color: #FFF8F2 !important;

  * {
    font-family: 'Chillax-semibold' !important;
    color: #474CAE !important;
  }
}

.popupGreen {
  border: 3px solid #4BBBB7;

  * {
    color: #4BBBB7 !important;
  }
}

.iconButton {
  position: absolute !important;
  right: 8px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.icon {
  width: 30px !important;
  height: 30px !important;
}
