@use "src/assets/styles/variables";

.content {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (min-width: variables.$media_l) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 16px;
  }
}

.profileItem {
  background-color: #595EB6;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  position: relative;
  gap: 16px !important;
}

.text {
  color: #FFF !important;
  font-family: 'Chillax-Semibold' !important;
  font-size: 20px !important;
  line-height: 20px !important;
  width: 100% !important;
  padding-left: 16px !important;
}

.counter {
  @extend .text;
  font-size: 14px !important;
  text-align: right !important;
  padding-right: 16px !important;
  font-family: 'Chillax-Medium' !important;
}

.avatar {
  @media screen and (min-width: variables.$media_l) {
    grid-area: 1/1/2/3;
  }

  @media screen and (min-width: variables.$media_hd) {
    grid-area: 1/1/2/3;
  }
}

.name {
  @media screen and (min-width: variables.$media_l) {
    grid-area: 1 / 3 / 2 / 6;
  }

  @media screen and (min-width: variables.$media_hd) {
    grid-area: 1 / 3 / 2 / 6;
  }
}

.description {
  @media screen and (min-width: variables.$media_l) {
    grid-area: 2 / 1 / 3 / 4;
  }
}

.rules {
  @media screen and (min-width: variables.$media_l) {
    grid-area: 2 / 4 / 3 / 6;
  }
}

.pictures {
  @media screen and (min-width: variables.$media_l) {
    grid-area: 3 / 1 / 4 / 6;
  }
}
