.container {
  height: 500px;
  display: grid;
  grid-template-rows: 70% 30%;
  color: #fff !important;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  max-width: 430px;

  @media screen and (min-width: 992px) {
    width: 80%;
    overflow: hidden;
    border-radius: 20px;
    justify-self: center;
    border: 2px solid rgba(213, 146, 103, 1);
  }
}

.review {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.backgroundImage {
  width: 120%;
  left: -10%;
  position: absolute;
}

.header {
  z-index: 999;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 50% 1fr;
  overflow: hidden;
}

.icons {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.location {
  background: rgba(13, 25, 25, 1);
  width: 100%;
  height: 85px;
  transform: translateY(-25px);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 5px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 50px;
    height: 3px;
    border-radius: 5px;
    background: linear-gradient(110.85deg, #cac59b 3.56%, #d59267 47.17%, #ec2a00 103.93%);
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.icon {
  width: 50px !important;
  height: 50px !important;
  margin: 10px auto !important;
}

.baner {
  border-top: 1px solid rgba(213, 146, 103, 1);
  border-bottom: 1px solid rgba(213, 146, 103, 1);
  display: flex;
  padding: 10px;
  gap: 10px;
  z-index: 99999999999999999999;

  @media screen and (min-width: 992px) {
    border-bottom: none;
    position: relative;

    &:hover .description {
      background-color: rgba(0, 0, 0, 0.9);
      opacity: 1;
    }
  }
}

.description {
  display: none;

  @media screen and (min-width: 992px) {
    width: 100%;
    position: absolute;
    height: 400px;
    bottom: 150px;
    display: flex;
    opacity: 0;
    left: 0;
    transition: background-color 0.2s, opacity 0.4s;
    z-index: -100;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
}

.profileImage {
  height: 80px;
  width: 80px;
  align-self: center;
  border-radius: 50%;
  margin-right: 10px;
}

.details {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
