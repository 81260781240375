.container {
  background-color: #474CAE;
  width: max-content;
  height: 40px;
  position: relative;
  border-radius: 8px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding: 0 8px;
  gap: 8px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -8px;
  top: -8px;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  color: #474CAE;
}

.detailsButton {
  background-color: #ff8552;
  margin-right: 8px;
  color: #fff;
  border: 2px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
}

.detailsButton:hover {
  background-color: #ff8552 !important;
  border-color: #fff !important;
  color: #fff !important;
}

.icon {
  width: 20px !important;
  height: 20px !important;

  svg {
    width: 20px !important;
    height: 20px !important;
  }
}

.select {
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% - 5px);
  height: 110px;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow-y: auto;
  padding: 4px;
  padding-top: 8px;
  background-color: #474CAE;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.selectButton {
  background-color: #fff;
  border: 2px solid #fff;
  color: #474CAE;
  height: 30px;
  padding: 0;

  p {
    padding: 0;
    margin: 0;
    font-size: 12px;
  }
}

.selectButton:hover {
  background-color: #ff8552 !important;
  border-color: #fff !important;
  color: #fff !important;
}

.selectButtonSelected {
  background-color: #ff8552 !important;
  border-color: #fff !important;
  color: #fff !important;
}
