@use "src/assets/styles/variables";

.container {
  width: 100%;
  height: 94%;
  background-color: #595eb6;
  padding: 16px;
  border-radius: 8px;
  position: relative;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
}

.mapContainer {
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
}

.map {
  height: 100%;
  border-radius: 8px;
  border: 4px solid #fff;
}

.form {
  width: 100%;
  max-height: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.formHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  position: relative;
  width: 100%;
  padding: 10px;

  & > * {
    color: #474CAE !important;
  }
}

.formContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-y: auto;
}

.addNewLocationButton {
  margin-top: 24px !important;

  background: #474cae !important;
  color: #fff !important;
  height: 45px;
  -webkit-box-shadow: 0 0 7px -2px rgba(0, 0, 0, 1) !important;
  -moz-box-shadow: 0 0 7px -2px rgba(0, 0, 0, 1) !important;
  box-shadow: 0 0 7px -2px rgba(0, 0, 0, 1) !important;
}

.icon {
  position: absolute !important;
  left: 0 !important;
  align-self: center !important;
}

.text {
  color: #FFF !important;
  font-family: 'Chillax-Semibold' !important;
  font-size: 18px !important;
  line-height: 20px !important;
  width: 100% !important;

  @media screen and (min-width: variables.$media_l) {
    font-size: 20px !important;
  }
}

.locationsList {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .4);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;

  * {
    scrollbar-width: auto !important;
    scrollbar-color: #595eb6 #ffffff !important;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px !important;
    height: 4px !important;
  }

  *::-webkit-scrollbar-track {
    background: #fff !important;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #595eb6 !important;
    border-radius: 3px !important;
    border: 0 solid #ffb8b8 !important;
  }
}

.locationsListContent {
  width: 90%;
  height: max-content;
  max-height: 96%;
  padding: 32px;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  border-radius: 16px;
  border: 2px solid #474CAE;

  @media screen and (min-width: variables.$media_l) {
    width: max-content;
  }
}

.locationButton {
  width: 100% !important;
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
  gap: 8px !important;
  text-transform: none !important;

  @media screen and (min-width: variables.$media_l) {
    gap: 32px !important;
  }

  svg {
    width: 30px;
  }

  p {
    text-transform: none !important;
    text-align: left !important;
    width: 100% !important;
    font-size: 12px !important;

    @media screen and (min-width: variables.$media_l) {
      font-size: 16px !important;
    }
  }
}

.locationsListWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 300px;
  overflow-y: scroll;
  width: 100%;
}

.locationButtonSelected {
  border: 3px solid #ff6a00 !important;
}

.emptyTag {
  color: #474CAE !important;
}

.error {
  color: #ff3333 !important;
}


