.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  flex-direction: column;
  gap: 40px;
}

.button {
  padding: 10px 50px !important;
  background: linear-gradient(121.49deg, #cac59b 7.09%, #d59267 61.5%, #ec2a00 134.79%),
  linear-gradient(0deg, #000000, #000000) !important;
  color: #fff !important;
}
