.container {
width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;

  p {
    margin: 0;
    padding: 0;
  }
}

.selectedSlots {
  font-family: 'Chillax-semibold';
  color: #474CAE;
  margin: 0;
  padding: 0;
}

.content {
display: flex;
  gap: 6px;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
}

.slot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px  6px;
  border-radius: 8px;
  background-color: #474CAE;
  color: #fff;

  span {
    font-family: 'Chillax-semibold';
    font-size: 14px;
  }

  p {
    font-family: 'Chillax-medium';

    font-size: 12px;
  }
}