@use "src/assets/styles/variables";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;

  @media screen and (min-width: variables.$media_l) {
    min-width: 400px;
  }
}

.header {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 0.3fr;
}

.headerButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.iconButton {
  height: 30px !important;
  width: 30px !important;
  color: #474CAE !important;
}

.input {
  border-radius: 6px;
  border: 1px solid;
  font-family: 'Chillax-Medium';
  color: #474CAE;
  padding-left: 8px;
  width: 90%;

  @media screen and (min-width: 850px) {
    width: 60%;
  }
}

.addButton {
  @extend .iconButton;

}

.content {
  width: 100%;
  display: flex;
  gap: 8px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  max-height: 120px;
  overflow-y: auto;
  height: 100%;
}

.contentHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 16px;
}

.bold {
  font-family: 'Chillax-semibold' !important;
  color: #474CAE !important;
}

.modalName {
  @extend .bold;

  font-size: 24px !important;
}

.contentName {
  @extend .bold;

  font-size: 16px !important;
}

.addFirstItem {
  font-family: 'Chillax-Medium' !important;
  color: #474CAE !important;
  font-size: 14px !important;
}
