@use 'src/assets/styles/variables';

.container {
  position: fixed;
  width: 100vw !important;
  height: 100vh;
  background-color: #474CAE;
  bottom: 0;
  left: 0;
  z-index: 99999999999;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  gap: 32px;
  padding: 32px;
  display: flex;
  padding-top: 64px;
  animation: slideInFromBottom 0.5s ease-out forwards;
  overflow-y: auto;

  @media screen and (min-width: variables.$media_l) {
    padding-right: 64px;
    padding-top: 32px;
  }
}

.closeButton {
  position: absolute !important;
  right: 8px !important;
  top: 8px !important;
  color: #fff !important;
  width: 50px !important;
  height: 50px !important;

  svg {
    width: 50px !important;
    height: 50px !important;
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
