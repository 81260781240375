@use "src/assets/styles/variables";

.container {
  grid-column: 1/5;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  gap: 300px;
  height: 105vh;
  overflow: hidden;
  z-index: 99;
}

.background {
  position: absolute;
  top: -20%;
  left: 0;
  bottom: 0;
  right: 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed !important;

  @media screen and (min-height: 700px) {
    top: 0;
  }
}

.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999;
  max-width: 1500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 130px;
  align-items: center;
  gap: 24px;

  @media screen and (min-width: variables.$media_l) {
    gap: 36px;
  }
}

.bannerText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  :last-child {
    font-family: 'Chillax-bold' !important;
    color: #515C72 !important;
    text-align: center !important;
    text-transform: uppercase !important;
    font-size: 16px !important;

    @media screen and (min-width: variables.$media_l) {
      font-size: 28px !important;
    }
  }
}

.bannerTextIcon {
  width: 300px !important;
  justify-self: center !important;
  height: 50px !important;

  @media screen and (min-width: variables.$media_l) {
    width: 800px !important;
    height: 80px !important;
  }

  @media screen and (min-width: variables.$media_xxl) {
    width: 1000px !important;
    height: 100px !important;
  }
}

.button {
  color: #fff !important;
  background-color: #FF8D5D !important;
  font-family: 'Chillax-semibold' !important;
  text-transform: none !important;
  border-radius: 20px !important;
  width: 170px !important;
  font-size: 20px !important;
  height: 50px !important;
  -webkit-box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25) !important;
  -moz-box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25) !important;
  box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25) !important;

  @media screen and (min-width: variables.$media_l) {
    width: 320px !important;
    height: 65px !important;
    font-size: 28px !important;
  }
}

.detailsWrapper {
  padding: 0 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  @media screen and (min-width: variables.$media_l) {
    margin-top: 46px;
  }

  @media screen and (min-width: variables.$media_xl) {
    margin-top: 64px;
  }

}

.info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  text-align: left !important;

  * {
    color: #515C72 !important;
    text-align: left !important;
  }

  p {
    font-family: 'Chillax-semibold' !important;
    font-size: 22px !important;
    line-height: 16px !important;

    @media screen and (min-width: variables.$media_l) {
      font-size: 58px !important;
      line-height: 38px !important;
    }
  }

  div {
    p {
      line-height: 18px !important;
      text-align: center !important;
      font-family: 'Chillax-semibold' !important;
      font-size: 16px !important;

      @media screen and (min-width: variables.$media_l) {
        font-size: 24px !important;
        line-height: 24px !important;
        text-align: left !important;
      }
    }

  }
}

