.container {
  display: flex;
  height: 100%;
  width: 100%;
  min-height: inherit;
  flex: 1 1 auto;
  justify-content: center;
}

.content {
  flex: 1 1 auto;
  max-width: 1320px;
  padding: 0 30px;
  min-height: inherit;
  height: 100%;
  width: 100%;
}
