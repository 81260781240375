@use "src/assets/styles/variables";

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 99;

  @media screen and (min-width: variables.$media_l) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    place-content: space-between;
    gap: 40px;
  }
}

.icon {
  display: none;
  z-index: 0;

  @media screen and (min-width: variables.$media_xl) {
    display: block;
    bottom: 30%;
    position: absolute;
    left: -15%;
  }
}

.button {
  margin-top: 0 !important;
}


