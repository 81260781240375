@use 'src/assets/styles/variables';

.container {
  justify-content: space-between !important;
  align-items: center !important;
  gap: 24px !important;
  background-color: #fff !important;
  border-radius: 16px !important;
  padding: 8px 16px !important;
  min-width: max-content !important;
  border: 3px solid #fff !important;
  cursor: pointer !important;
  transition: border-color 0.3s;
  display: flex !important;
  justify-content: space-between !important;

  @media screen and (min-width: variables.$media_l) {
    display: grid !important;
    grid-template-columns: repeat(6, 1fr) !important;
  }
}

.active {
  border-color: #FF8552 !important;
}

.info {
  display: flex;
  flex-direction: column;
  grid-column: 1/3;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  grid-column: 3/7;
}

.time {
  width: 100%;
  display: flex;
  justify-content: space-between;

  p {
    font-family: 'Chillax-regular' !important;
    font-size: 14px !important;
  }

  :first-child {
    text-align: left !important;
  }

  :last-child {
    text-align: right !important;
  }
}

.progress {
  width: 100%;
  background: #DAD7FE;
  color: #fff;
  height: 8px;
  border-radius: 12px;
  overflow: hidden;

  div {
    width: 70%;
    background: #474CAE;
    height: 100%;
  }
}


.name {
  font-size: 14px !important;
  color: #000 !important;
  font-family: 'Chillax-bold' !important;
}

.nextBooking {
  color: #595EB6 !important;
  font-size: 12px !important;
  font-family: 'Chillax-Medium' !important;

  span {
    font-family: 'Chillax-semibold' !important;
  }
}
