@use 'src/assets/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 16px;

  p {
    color: #474CAE !important;
    font-size: 28px !important;
    font-family: 'Chillax-semibold' !important;
  }
}

.closeIcon {
  width: 40px !important;
  height: 40px !important;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.textWrapper {
  display: flex;
  gap: 4px;
}

.inputsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (min-width: variables.$media_l) {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    margin-top: 16px;
  }
}

.inputWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  input {
    width: 100%;
  }
}

.text {
  color: #474CAE !important;
  font-size: 14px !important;

  @media screen and (min-width: variables.$media_l) {
    font-size: 15px !important;
  }

  @media screen and (min-width: variables.$media_xl) {
    font-size: 15px !important;
  }
}

.bold {
  @extend .text;

  font-family: 'Chillax-semibold' !important;
}

.light {
  @extend .text;

  font-family: 'Chillax-Regular' !important;
}

.button {
  background-color: #FF8552 !important;
  border: 3px solid #fff !important;
  width: 180px !important;
  color: #fff !important;
  font-family: 'Chillax-semibold' !important;
  align-self: center !important;
}
