@use 'src/assets/styles/variables';

.container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 40px 1fr;
  gap: 10px;
  position: relative !important;

  * {
    scrollbar-width: auto !important;
    scrollbar-color: #ffffff #595eb6 !important;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px !important;
    height: 4px !important;
  }

  *::-webkit-scrollbar-track {
    background: #595eb6 !important;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #ffffff !important;
    border-radius: 3px !important;
    border: 0 solid #ffb8b8 !important;
  }
}

.schedule {
  border-radius: 16px !important;
}

.calendar {
  width: 100%;
  height: 100%;
}

.header {
  background: #474CAE;

  * {
    color: #fff !important;
  }
}

// event styles

.eventItem {
  width: 100%;
  height: 100%;
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  padding: 0 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #069999 !important;

  p {
    font-family: 'Chillax-Medium' !important;
  }
}

.eventItemAccept {
  background: #474CAE !important;
}

// header styles

.headerContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: #474cae;
  margin-bottom: 8px;
  padding: 0 8px;
}

.headerTitle {
  font-family: 'Chillax-bold' !important;
  font-size: 20px !important;
  color: #fff !important;
}

.headerBookedBy {
  font-family: 'Chillax-Semibold' !important;
  font-size: 18px !important;
  color: #fff !important;
}


// content styles

.contentPopupContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  max-height: 300px;
}

.contentPopupContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.contentItemWrapper {
  display: grid;
  grid-template-columns: 30% 1fr;
  align-items: center;
  gap: 8px;

  * {
    color: #fff !important;
  }

  span {
    font-family: 'Chillax-Semibold' !important;
    font-size: 14px !important;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 16px;

    p {
      font-family: 'Chillax-Regular' !important;
      font-size: 14px !important;
    }

    span {
      font-family: 'Chillax-Regular' !important;
      font-size: 16px !important;
    }
  }
}

.contentItemName {
  font-family: 'Chillax-Semibold' !important;
  font-size: 18px !important;
}

.contentButtons {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.contentButton {
  border-radius: 12px !important;
  color: #fff !important;
  font-family: 'Chillax-Medium' !important;
  font-size: 18px !important;
  text-transform: none !important;
}

.contentAcceptButton {
  @extend .contentButton;

  background-color: #049798 !important;
}

.contentDeclineButton {
  @extend .contentButton;

  background-color: #F58B74 !important;
}

.contentSeparator {
  width: 100%;
  height: 1px;
  background-color: #FCF9F6;
}

.buttons {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}

.viewButton {
  border: 3px solid #474cae !important;
  color: #474cae !important;
  font-family: 'Chillax-semibold' !important;
  text-transform: none !important;
}

.timeOffModal {
  @extend .viewButton;

  margin-left: 16px !important;
  font-size: 10px !important;

  @media screen and (min-width: variables.$media_l) {
    font-size: 16px !important;
  }
}

.activeViewButton {
  background: #F58B74 !important;
  color: #fff !important;
  border-color: #F58B74 !important;
}

.addReservationButton {
  position: absolute !important;
  bottom: 32px !important;
  right: 32px !important;
  border-radius: 50% !important;
  background-color: #F58B74 !important;
  width: 50px !important;
  height: 50px !important;
  background-color: #fff;
  color: #fff !important;
}

.modal {
  width: 100%;
  padding: 0 !important;
  height: 80%;
  margin: 0;
  max-height: 70vh !important;

  @media screen and (min-width: variables.$media_xl) {
    max-width: unset !important;
    width: max-content !important;
    height: max-content !important;
    top: 55% !important;
    max-height: 70vh !important;
    padding: 16px !important;
  }
}

.loader {
  width: 100%;
  height: 100%;
}
