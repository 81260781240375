@use "src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;

  @media screen and (min-width: variables.$media_l) {
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr minmax(600px, 1fr);

  }
}

.logo {
  cursor: pointer !important;
  max-width: 300px;
}

.content {
  flex-grow: 1;
  background-color: #474CAE;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: space-evenly;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 0 20px;
  min-height: 600px;

  @media screen and (min-width: variables.$media_l) {
    border-top-right-radius: 0;
    border-bottom-left-radius: 20px;
    -webkit-box-shadow: 0 0 16px -3px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0 0 16px -3px rgba(66, 68, 90, 1);
    box-shadow: 0 0 16px -3px rgba(66, 68, 90, 1);
    border: none;
    outline: #fff solid 4px;
  }
}

.banner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
}

.mobile {
  @media screen and (min-width: variables.$media_l) {
    display: none !important;
  }
}

.desktop {
  display: none;

  @media screen and (min-width: variables.$media_l) {
    display: flex;
  }
}
