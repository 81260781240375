@use "src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  max-width: 700px !important;
  width: 100%;
  margin: auto;

  @media screen and (min-width: variables.$media_l) {
    width: 80%;
  }
}

.title {
  color: #595EB6 !important;
  text-align: center !important;
  font-size: 60px !important;
  font-family: 'Chillax-Semibold', sans-serif !important;
  line-height: 38px !important;
  width: 100%;
  text-transform: uppercase !important;

  @media screen and (min-width: variables.$media_l) {
    text-align: left !important;
  }

  @media screen and (min-width: variables.$media_xl) {
    font-size: 68px !important;
    line-height: 45px !important;
  }

}

.content {
  background-color: #595EB6;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #FFF !important;

  @media screen and (min-width: variables.$media_l) {
    text-align: left !important;
    border-radius: 10px;
  }
}

.inputBox {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.button {
  width: 200px !important;
  height: 50px !important;
  border: 4px solid #FFF !important;
  font-family: 'Chillax-Regular', sans-serif !important;
  text-transform: capitalize !important;
  font-size: 20px !important;
  -webkit-box-shadow: 0 0 20px -7px rgba(66, 68, 90, 1) !important;
  -moz-box-shadow: 0 0 20px -7px rgba(66, 68, 90, 1) !important;
  box-shadow: 0 0 20px -7px rgba(66, 68, 90, 1) !important;
  background: #FF8552 !important;
  border-radius: 15px !important;
  align-self: flex-end !important;

  @media screen and (min-width: variables.$media_l) {
    align-self: center !important;
  }
}

.submit {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (min-width: variables.$media_l) {
    display: grid;
    grid-template-columns: 1fr 200px;
  }
}

.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999999999;
}
