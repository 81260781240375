.wrapper {
    width: 100%;
}

.innerWrapper {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    background-color: #F8EDE5;
}

.innerWrapperTransparency {
    background-color: rgba(248, 237, 229, 0.8);
}

.loader {
    display: flex;
    align-items: center;
    position: relative;
    background-repeat: no-repeat;
    background-size: 80px;
    background-position: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
}

.eclipse {
    animation: eclipse 3s infinite ease;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 50%;
    background-color: #F8EDE5;
    transform: translateX(-100%);
}

@-webkit-keyframes eclipse {
    from {
        -webkit-box-shadow: inset 100px 0 0 0 #F8EDE5;
        box-shadow: inset 100px 0 0 0 #F8EDE5;
    }
    to {
        -webkit-box-shadow: inset -100px 0 0 0 #F8EDE5;
        box-shadow: inset -100px 0 0 0 #F8EDE5;
    }
}

@keyframes eclipse {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(100%);
    }
}
