@use "src/assets/styles/variables";

.container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  max-width: 2200px;
}

.row {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 20px;
  gap: 20px;

  @media screen and (min-width: 996px) {
    display: flex;
    justify-content: space-around;
  }
}

.section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  & > * {
    margin: 5px 0 !important;
  }

  &::after {
    content: "";
    height: 100%;
    width: 4px;
    position: absolute;
    background-color: variables.$footer_primary_color;
    right: 0;
    border-radius: 5px;
    display: none;
  }

  &:nth-child(4),
  &:nth-child(2) {
    &::after {
      display: none;
    }

    @media screen and (min-width: 996px) {
      &::after {
        display: block;
      }
    }
  }

  &:last-child {
    &::after {
      display: none;
    }
  }

  @media screen and (min-width: 996px) {
    padding-right: 40px;
    align-items: center;

    &::after {
      display: flex;
    }
  }
}

.sectionGreen {

  &::after {
    background-color: #4BBBB7;
  }
}

.link {
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
  }
}


.logo {
  width: 70px;
  height: 70px;
  align-self: center;
  justify-self: center;
}

.logoGreen {
  * {
    fill: #4BBBB7;
  }
}
