@use "src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
  height: max-content;

  @media screen and (min-width: variables.$media_xl) {
    width: 100%;
    background-color: #474cae;
    padding: 30px 50px;
    border-radius: 20px;
    color: #fff !important;
    border: 4px solid #fff !important;
    -webkit-box-shadow: 0 0 17px -11px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0 0 17px -11px rgba(66, 68, 90, 1);
    box-shadow: 0 0 17px -11px rgba(66, 68, 90, 1);
  }
}

.rating {
  color: #333333 !important;

  @media screen and (min-width: variables.$media_xl) {
    color: #fff !important;
  }
}

.title {
  font-size: 32px !important;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.row {
  display: flex;
  align-items: center;
  gap: 5px;
}
