@use "src/assets/styles/variables";

.container {
  width: 100%;
  background-color: #F8F2ED;
  display: grid;
  grid-template-rows: 80px;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 30px;
  position: relative;
  min-height: 80%;

  @media screen and (min-width: variables.$media_l) {
    height: 80%;
    align-self: center;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    background-color: #fff;
    align-items: center;
    gap: 16px;
    padding: 0;
    border: 2px solid #474CAE;
    border-left: none;
    border-bottom-right-radius: 16px;
    border-top-right-radius: 16px;
  }
}

.containerMenuIsOpen {
  display: grid;
  grid-template-rows: 80px 1fr;

  @media screen and (min-width: variables.$media_l) {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 100px;
  }
}

.title {
  position: absolute !important;
  top: 30px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  color: #474CAE !important;
  font-family: 'Tanohe-Sans' !important;
  font-weight: bold !important;
  font-size: 32px !important;
  width: 100%;
  text-align: center !important;
  text-transform: uppercase !important;
}


.navigation {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stepsPreview {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;


  & > * {
    font-family: 'Tanohe-Sans' !important;
    font-size: 24px !important;
    line-height: 24px !important;
    text-transform: uppercase !important;
    font-weight: 800 !important;
  }
}

.arrowIcon {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.stepText {
  font-family: 'Chillax-semibold' !important;
  color: #474CAE !important;
  font-size: 20px !important;
  text-transform: uppercase !important;
  margin-top: 16px !important;
}

.iconButton {
  width: 80px !important;
  height: 80px !important;
  transform: rotate(-90deg) !important;
  transition: transform 0.3s ease-in-out !important;
}

.button {
  transition: transform 0.3s ease-in-out !important;
}

.buttonOpenMenu {
  transform: rotate(90deg) !important;
}

.mobileMenuOpen {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  overflow-y: auto;
  list-style: none;
  padding: 0;

  @media screen and (min-width: variables.$media_l) {
    justify-content: center;
    padding-left: 24px;
  }
}

.createOrganizationText {
  display: flex;
  width: 100%;
  grid-column: 1/3;
  background: #000;
}

.stepButton {
  width: 100% !important;
  height: 80px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  border-radius: 20px !important;
  padding: 8px !important;
  cursor: pointer !important;
}

.stepItem {
  font-family: 'Tanohe-Sans' !important;
  font-weight: 800 !important;
  font-size: 22px !important;
  text-transform: uppercase !important;
}

.pointer {
  width: 30px;
  height: 30px;
  border: 1px solid #FF8552;
  border-radius: 50%;
}

.activePointer {
  background-color: #FF8552;
}

.stepButtonActive {
  border: 4px solid #474CAE !important;
  background-color: #FFFFFF !important;

  @media screen and (min-width: variables.$media_l) {
    border: none !important;
  }
}

.hidden {
  display: none !important;
}

.stepButtonError {
  color: tomato !important;
}

.disabled {
  color: #b0b0b0 !important;
}
