@use "src/assets/styles/variables";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;

  @media screen and (min-width: variables.$media_l) {
    align-self: center;
    background-color: #fff;
    border: 1px solid #474CAE;
    padding: 16px 40px 30px 16px;
    border-bottom-right-radius: 16px;
    border-top-right-radius: 16px;
    max-height: 100%;
    height: 100%;
    justify-content: center;
    gap: 0;
    overflow-y: auto;
    min-height: 80%;
  }

  @media screen and (min-width: variables.$media_l) and (min-height: 800px) {
    height: max-content;
    padding: 16px 80px 60px 16px;
  }
}

.header {
  height: 100px;
  display: grid;
  grid-template-columns: 1fr 50px;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.wrapper {
  width: 100%;

  * {
    font-family: 'Tanohe-Sans' !important;
    font-weight: 800 !important;
    font-size: 24px !important;
    color: #474CAE !important;
    text-align: center !important;
  }
}

.arrowButtonMobile {
  transform: rotate(0) !important;
  transition: transform 0.3s;
}

.arrowButtonOpenMobile {
  transform: rotate(-180deg) !important;
}

.arrowButton {
  position: absolute !important;
  right: 16px !important;
  top: 50% !important;
  transform: translateY(-50%) rotate(-90deg) !important;
  transition: 0.3s !important;
}

.arrowButtonOpen {
  transform: translateY(-50%) rotate(90deg) !important;
}

.menuContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  width: 100%;

  @media screen and (min-width: variables.$media_l) {
    width: 90%;
  }
}

.menuItem {
  display: grid;
  grid-template-columns: 1fr 30px;
  width: 100%;
  max-width: 350px;
  cursor: pointer;
  height: 70px;
  border-radius: 16px;
  align-items: center;
  padding: 0 16px;
}

.name {
  font-family: 'Tanohe-Sans' !important;
  font-weight: 800 !important;
  text-transform: uppercase !important;
}

.menuItemActiveStep {
  border: 3px solid #474CAE;
  background-color: #fff;
}

.dot {
  width: 30px;
  height: 30px;
  background-color: #fff;
  border: 1px solid #FF8552;
  border-radius: 50%;
}

.dotActive {
  background-color: #FF8552;
}
