@use "src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  @media screen and (min-width: variables.$media_l) {
    width: calc(100% + 24px);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.control {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  right: 0;

  * {
    fill: #868ad9;
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (min-width: variables.$media_l) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 80%;
    gap: 32px;

    :first-child {
      grid-column: 1/3;
    }
  }
}

.fieldWrapper {
  width: 100%;
  display: grid;
  min-height: 100px;
  grid-template-rows: 24px 1fr;
  grid-template-columns: 1fr;
  position: relative;
}

.tooltip {
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
}

.title {
  margin-left: 8px !important;
  font-family: 'Chillax-Semibold' !important;
  color: #474CAE !important;
  font-size: 24px !important;

  @media screen and (min-width: variables.$media_l) {
    font-size: 32px !important;
  }
}

.itemTitle {
  @extend .title;

  font-size: 18px !important;
}

.itemTitleCenter {
  @extend .itemTitle;

  text-align: center !important;
}

.titleError {
  color: #ec2a00 !important;
}

