@use "src/assets/styles/variables";

.container {
  width: 100%;
  position: relative;
  overflow: hidden;

  @media screen and (min-width: variables.$media_l) {
    border-radius: 16px;
  }
}

.image {
  height: 60vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.gallery {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #000;
  z-index: 9999999;
}

.galleryImage {
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.closeButton {
  position: fixed !important;
  top: 10% !important;
  left: 10% !important;
  z-index: 9999999999999999 !important;
}

.mobile {
  z-index: 999;

  @media screen and (min-width: variables.$media_m) {
    display: none;
  }
}

.iconButton {
  position: absolute !important;
  width: 50px !important;
  height: 50px !important;
  background-color: #fff !important;
  z-index: 99 !important;
  top: 56px !important;
  left: 16px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding-right: 12px !important;

  * {
    fill: #474cae !important;
  }

  @media screen and (min-width: variables.$media_l) {
    top: 16px !important;
  }
}
