.carouselContainer {
  border-radius: 16px;
  overflow: unset;
  width: 100%;
}

.carouselContainerNoOverflow {
  border-radius: 16px;
  width: 100%;
}

.carousel {
  display: flex;
}

.item {
  padding: 10px;
  min-height: 200px;
}

