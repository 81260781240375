@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");


body {
    margin: 0;
    padding: 0;
    font-family: Comfortaa, sans-serif;
}

* {
    box-sizing: border-box;
}

a {
    text-decoration: none !important;
}

