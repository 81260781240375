.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #474CAE !important;
  align-items: center !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 400px;
  text-align: center !important;
  padding: 24px;
  border-radius: 16px;

}

.content {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 46px;
  gap: 16px;
}

.button {
  color: #474CAE !important;
  padding: 10px 20px !important;
  border-radius: 10px !important;
  border: 3px solid #474CAE !important;
  font-size: 13px !important;
  font-family: 'Chillax-Medium' !important;
  text-transform: none !important;
}

.createOfferButton {
  border-color: #fff !important;
  background-color: #FF8552 !important;
  color: #fff !important;
}

.createOfferButton:disabled {
  background-color: #a4a4a4 !important;
}

.addPayment {
  font-family: 'Chillax-Semibold' !important;
}

.logo {
  width: 100px !important;
  height: 100px !important;
}
