.container {
  justify-content: space-between;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 8px;
  background-color: #fff;
  border-radius: 16px;
  padding: 8px;
}

.info {
  display: flex;
  flex-direction: column;
  grid-column: 1/3;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  grid-column: 3/6;
}

.time {
  width: 100%;
  display: flex;
  justify-content: space-between;

  p {
    font-family: 'Chillax-regular' !important;
    font-size: 14px !important;
  }

  :first-child {
    text-align: left !important;
  }

  :last-child {
    text-align: right !important;
  }
}

.progress {
  width: 100%;
  background: #DAD7FE;
  color: #fff;
  height: 8px;
  border-radius: 12px;
  overflow: hidden;

  div {
    width: 70%;
    background: #474CAE;
    height: 100%;
  }
}

.iconButton {
  grid-column: 6/7 !important;
  color: grey !important;
  transform: rotate(-90deg) !important;
}

.name {
  font-size: 14px !important;
  color: #000 !important;
  font-family: 'Chillax-bold' !important;
}

.nextBooking {
  color: #595EB6 !important;
  font-size: 12px !important;
  font-family: 'Chillax-Medium' !important;

  span {
    font-family: 'Chillax-semibold' !important;
  }
}
