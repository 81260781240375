@use "src/assets/styles/variables";

.section {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (min-width: variables.$media_s) {
    padding: 0 10vw;
  }
}

.sectionHeader {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (min-width: variables.$media_s) {
    width: 40vw;
    min-width: 450px;
  }
}

.roadMap {
  width: 100%;
  max-width: 1300px;
  margin: 20px auto;
  height: 100%;
}

.slider {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.arrows {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.iconButton {
  & > * {
    fill: #474CAE !important;
    font-size: 30px;
  }
}

.slideBox {
  background-color: #FCF9F6;
  padding: 20px;
  -webkit-box-shadow: 8px 8px 20px -12px rgba(66, 68, 90, 1);
  -moz-box-shadow: 8px 8px 20px -12px rgba(66, 68, 90, 1);
  box-shadow: 8px 8px 20px -12px rgba(66, 68, 90, 1);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  h3 {
    font-family: 'Chillax-Bold' !important;
    font-size: 18px !important;
  }

  h4 {
    font-family: 'Chillax-Regular' !important;
    font-size: 16px !important;
  }

}

.title {
  font-family: "Chillax-semibold" !important;
  font-size: 20px !important;

  @media screen and (min-width: variables.$media_l) {
    font-size: 64px !important;
  }
}

.content {
  font-family: 'Chillax-Medium' !important;
  font-size: 16px !important;
}

.desktop {
  display: none;

  @media screen and (min-width: variables.$media_s) {
    display: block;
  }
}

.mobile {
  @media screen and (min-width: variables.$media_s) {
    display: none;
  }
}