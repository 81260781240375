@use 'src/assets/styles/variables';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.button {
  background-color: #FF8552 !important;
  color: #fff !important;
  font-family: 'Chillax-semibold' !important;
  text-transform: none !important;
  -webkit-box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25) !important;
  -moz-box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25) !important;
  box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25) !important;
}

.prevButton {
  @extend .button;

  background-color: #474CAE !important;
}
