.container {
  display: flex;
  flex-direction: column;
}

.button {
  background: #0d1a1a !important;
  border-radius: 20px !important;
  color: white !important;
  text-transform: none !important;
  border: 1px solid #d59267 !important;
  font-size: 13px !important;
  height: 40px;
}
