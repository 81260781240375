@use "src/assets/styles/variables";

.container {
  width: 100%;
  background-color: #595eb6;
  border-radius: 24px;
  padding: 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: variables.$media_l) {
    width: 100%;
    height: 100%;
    background-color: #595eb6;
    border-radius: 24px;
    padding: 16px;
    display: grid;
    gap: 16px;
    grid-template-rows: repeat(2, 1fr);
  }
}

.contentWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 100%;

  @media screen and (min-width: variables.$media_l) {
    overflow-x: auto;
    height: 100%;
  }
}

.itemsWrapper {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  list-style: none !important;
  padding: 0 !important;

  @media screen and (min-width: variables.$media_l) {
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    gap: 16px !important;
    list-style: none !important;
    padding: 0 !important;
    align-items: center !important;
  }
}

.categoryItem {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: variables.$media_l) {
    width: 150px !important;
    height: 150px !important;
    display: flex !important;
    flex-direction: column !important;
    gap: 8px !important;
  }
}

.categoryButton {
  width: 90px !important;
  height: 80px !important;
  border-radius: 18px !important;
  background-color: #fff !important;
  margin: 0 auto !important;

  @media screen and (min-width: variables.$media_l) {
    width: 150px !important;
    height: 130px !important;
  }
}

.categoryButtonActive {
  border: 5px solid #FF8552 !important;
}

.categoryName {
  text-align: center !important;
  font-family: "Chillax-Medium" !important;
  color: #fff !important;
  margin: 0 auto !important;
  font-size: 12px !important;
  width: 100% !important;

  @media screen and (min-width: variables.$media_l) {
    height: 20px !important;
    font-size: 14px !important;
    width: 150px !important;
  }
}

.icon {
  width: 60px;
  height: 60px;

  @media screen and (min-width: variables.$media_l) {
    width: 90px;
    height: 90px;
  }
}

.optionsWrapper {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  list-style: none !important;
  padding: 0 !important;

  @media screen and (min-width: variables.$media_l) {
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    gap: 16px !important;
    list-style: none !important;
    padding: 0 !important;
    align-items: center !important;
  }
}

.optionItem {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: variables.$media_l) {
    width: 150px !important;
    height: 150px !important;
    display: flex !important;
    flex-direction: column !important;
    gap: 8px !important;
  }
}

.optionButton {
  width: 90px !important;
  height: 80px !important;
  border-radius: 18px !important;
  background-color: #fff !important;
  margin: 0 auto !important;

  @media screen and (min-width: variables.$media_l) {
    width: 150px !important;
    height: 130px !important;
  }
}

.optionButtonActive {
  border: 5px solid #FF8552 !important;
}

.optionName {
  text-align: center !important;
  font-family: "Chillax-Medium" !important;
  color: #fff !important;
  margin: 0 auto !important;
  font-size: 12px !important;
  width: 100% !important;

  @media screen and (min-width: variables.$media_l) {
    height: 20px !important;
    font-size: 14px !important;
    width: 150px !important;
  }
}

.sectionName {
  font-family: 'Chillax-semibold' !important;
  color: #fff !important;
  font-size: 20px !important;
}
