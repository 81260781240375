@use "src/assets/styles/variables";

.container {
  background-color: #595eb6;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px 16px 32px 16px;
  border-radius: 16px;
  width: 100%;
  position: relative;
  min-height: 220px;

  * {
    scrollbar-width: auto !important;
    scrollbar-color: #ffffff #595eb6 !important;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px !important;
    height: 4px !important;
  }

  *::-webkit-scrollbar-track {
    background: #595eb6 !important;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #ffffff !important;
    border-radius: 3px !important;
    border: 0 solid #ffb8b8 !important;
  }

  @media screen and (min-width: variables.$media_l) {
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-template-rows: 40px 1fr;
    align-items: flex-start;
    gap: 8px;
    padding: 16px;
  }
}

.switchWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  grid-column: 2/3;
  grid-row: 1/3;

  p {
    font-family: 'Chillax-Semibold' !important;
    font-size: 16px !important;
    color: #fff !important;
  }
}

.title {
  color: #fff !important;
  font-family: 'Chillax-Semibold' !important;
  font-size: 16px !important;
  align-items: center !important;
}

.wrapper {
  display: flex;
  gap: 16px;
  align-items: center;
  position: relative;
  padding: 0 48px;
}

.tooltip {
  position: absolute !important;
  left: 170px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.imagesWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 24px;
  width: 100%;
  align-items: center;

  * {
    width: inherit;
  }

  @media screen and (min-width: variables.$media_l) {
    align-items: flex-start;
    grid-column: 1/2;
    overflow-x: auto;
    overflow-y: hidden;
    height: 100%;
  }
}

.photosWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;

  @media screen and (min-width: variables.$media_l) {
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.button {
  width: 80px !important;
  height: 80px !important;
  background-color: #fff !important;
  border-radius: 20px !important;
  color: #595EB6 !important;
  font-size: 112px !important;
  font-family: 'Chillax-Semibold' !important;
  min-width: 80px !important;
}

.error {
  color: #ec3636 !important;
  position: absolute !important;
  bottom: 8px !important;
  left: 60px !important;
  font-size: 12px !important;
}

.imageContainer {
  display: flex !important;
  gap: 8px !important;
  position: relative !important;
  justify-self: center !important;
  width: 120px !important;
  height: 120px !important;
  margin: 0 auto;

  img {
    width: 120px !important;
    height: 120px !important;
    border-radius: 16px !important;
    object-fit: cover !important;
  }
}

.iconButton {
  position: absolute !important;
  top: 8px !important;
  right: 8px !important;
  background-color: #fff !important;
  -webkit-box-shadow: 0 0 24px -15px rgba(66, 68, 90, 1) !important;
  -moz-box-shadow: 0 0 24px -15px rgba(66, 68, 90, 1) !important;
  box-shadow: 0 0 24px -15px rgba(66, 68, 90, 1) !important;
  width: 30px !important;
  height: 30px !important;

  & > * {
    color: #474CAE !important;
  }
}

.photosWrapperEmpty {
  display: flex;
  gap: 16px;
  flex-direction: column;
  width: 100%;
  align-items: center;

  @media screen and (min-width: variables.$media_l) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.imageBackground {
  background-color: #fff;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  min-width: 120px;

  img {
    width: 80px;
    height: 80px;
    border-radius: 16px;
  }
}

.emptyPhoto {
  width: 80px;
  height: 80px;
}

.mobile {
  @media screen and (min-width: variables.$media_l) {
    display: none;
  }
}

.desktop {
  display: none;

  @media screen and (min-width: variables.$media_l) {
    display: flex;
  }
}
