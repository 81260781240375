@use "src/assets/styles/variables";

.navbar {
  height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% + 64px);
  transform: translateX(-32px);
  padding: 20px 13px 0;

  @media screen and (min-width: variables.$media_l) {
    width: 100%;
    transform: none;
  }
}

.logo {
  width: 40px;
}

.logoText {
  display: none;

  @media screen and (min-width: variables.$media_l) {
    display: block;
  }
}

.row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.mobile {
  display: block !important;

  @media screen and (min-width: variables.$media_l) {
    display: none !important;
  }
}

.desktop {
  display: none !important;

  @media screen and (min-width: variables.$media_l) {
    display: block !important;
  }
}

.innerRowWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
