@use "src/assets/styles/variables";

.container {
  width: 100%;
  display: grid;
  grid-template-rows: 200px 1fr;
  gap: 10px;

  @media screen and (min-width: variables.$media_l) {
    grid-template-rows: 250px 1fr;
  }
}

.imageBox {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  -webkit-box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25);
  box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform .4s;
}

.container:hover .image {
  @media screen and (min-width: variables.$media_m) {
    transform: scale(1.1);
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
}

.text {
  font-size: 16px !important;
  color: #474CAE !important;

  @media screen and (min-width: variables.$media_l) {
    font-size: 18px !important;
  }
}
