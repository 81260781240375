@use "src/assets/styles/variables";

.navbar {
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 16px;
  padding-bottom: 25px;
  width: 100%;
  background-color: #FFF8F2 !important;
  max-width: 1540px;
  flex-grow: 1;
  align-self: center;
  margin: 0 auto;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  z-index: 99999999999999;
  position: sticky !important;
  top: 0;
  -webkit-box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25);
  box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25);

  @media screen and (min-width: 900px) {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    padding: 0 16px;
  }

  @media screen and (min-width: 1100px) {
    padding: 0 48px;
  }
}

.navbarOpenMenu {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.userMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

// grid positioning

.logo {
  padding-left: 8px;

  @media screen and (min-width: 900px) {
    grid-column: 1/2;
  }
}

.offers {
  @media screen and (min-width: 900px) {
    grid-column: 7/10;
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 1250px) {
    grid-column: 7/9;
  }
}

.userMenu {
  @media screen and (min-width: 900px) {
    grid-column: 10/11 !important;
  }
}

.form {
  width: 100%;

  @media screen and (min-width: 900px) {
    grid-column: 4/7 !important;
  }
}


.loader {
  @media screen and (min-width: 900px) {
    grid-column: 4/7 !important;
  }
}
