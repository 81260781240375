$media_xs: 420px;
$media_s: 576px;
$media_m: 768px;
$media_l: 992px;
$media_xl: 1280px;
$media_xxl: 1400px;
$media_hd: 1600px;

$navbar-height: calc(100vh - 100px);

$footer_primary_color: #474cae;
