@use "src/assets/styles/variables";

.content {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  z-index: 50;
  height: 420px;

  @media screen and (min-width: variables.$media_xs) {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }
}

