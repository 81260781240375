@use "src/assets/styles/variables";

.mainContent {
  gap: 0 !important;
  justify-content: center !important;
  align-items: center !important;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;

  @media screen and (min-width: variables.$media_l) {
    grid-row: 2/3;
  }
}

.container {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white !important;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 80%;
  flex-grow: 1;
  padding: 20px 0;
  margin: 10px;

  @media screen and (min-width: variables.$media_l) {
    display: grid;
    min-width: 900px;
    width: 70%;
    gap: 20px;
    max-width: 1400px;
    flex-grow: 0.6;
    margin: auto 0;
    grid-template-columns: 1fr 35%;
    grid-template-rows: 100px 1fr;
  }
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;

  span {
    margin: 0 10px;
  }
}

.button {
  color: white !important;
  background: linear-gradient(110.85deg, #cac59b 3.56%, #d59267 74.65%, rgba(236, 42, 0, 0.81) 103.93%),
    linear-gradient(0deg, #0d1a1a, #0d1a1a) !important;
  width: 150px !important;
  margin: 0 auto !important;
  text-transform: none !important;
}

.section {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: rgba(13, 26, 26, 1);
  border-radius: 20px;
  border: 1px solid rgba(213, 146, 103, 1);

  @media screen and (min-width: variables.$media_l) {
    grid-row: 1/3;
    height: max-content;
    gap: 30px;
    width: 80%;
    margin-left: auto;
  }
}

.accordion {
  box-shadow: none !important;

  &:last-child {
    display: none !important;
  }
}
