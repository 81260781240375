.content {
  width: 100%;
  height: 100%;
  padding: 48px 24px;
  overflow: auto;
  background: #F8EDE5;
  color: #474CAE !important;

  img {
    width: 100%;
  }

  @media all and (min-width: 1024px) {
    padding: 24px;
  }
}
