@use "src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
}

.title {
  font-size: 20px !important;
}

.content {
  display: grid;
  grid-template-columns: 150px 1fr;
  gap: 20px;
}

.contentWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.image {
  width: 100%;
  border-radius: 10px;
  max-height: 150px;
  object-fit: cover;
}

.name {
  @media screen and (min-width: variables.$media_m) {
    font-size: 20px !important;
  }
}

.rating {
  color: rgba(3, 151, 153, 1);

  @media screen and (min-width: variables.$media_m) {
    justify-self: flex-start !important;
  }
}

.button {
  align-self: flex-start !important;
  font-family: "Chillax-Regular" !important;
  font-size: 18px !important;
  position: relative !important;
  padding: 0 !important;

  &::after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: #aaa;
    bottom: 0;
    left: 0;
    position: absolute;
  }
}
