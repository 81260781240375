.modal {
  max-width: 450px !important;
  background-color: #F8F2ED !important;
}

.exitModal {
  display: flex;
  flex-direction: column;
  gap: 24px;

  p {
    font-family: 'Chillax-Semibold' !important;
    color: #474CAE !important;
    text-align: center !important;
    font-size: 18px !important;
  }
}

.exitModalButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.modalExitButton {
  background-color: #FF8552 !important;
  border: 3px solid #fff !important;
  text-transform: capitalize !important;
  color: #fff !important;
  font-family: 'Chillax-Medium' !important;

}

.modalStayButton {
  border: 3px solid #474CAE !important;
  text-transform: capitalize !important;
  color: #474CAE !important;
  font-family: 'Chillax-Medium' !important;
}

