@use "src/assets/styles/variables";

.container {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @media screen and (min-width: variables.$media_l) {
    grid-column: 1/3;
    grid-row: 2/3;
  }
}

.button {
  height: 45px;
  -webkit-box-shadow: 0 0 4px -2px rgba(0, 0, 0, 1) !important;
  -moz-box-shadow: 0 0 4px -2px rgba(0, 0, 0, 1) !important;
  box-shadow: 0 0 4px -2px rgba(0, 0, 0, 1) !important;
  border: 3px solid #474cae !important;
  color: #474cae !important;
  font-family: 'Chillax-Medium' !important;
  text-transform: capitalize !important;
  border-radius: 12px !important;
  font-size: 14px !important;
  width: 130px !important;
}

.button:disabled {
  background-color: #999998 !important;
}

.nextButton {
  @extend .button;

  background-color: #FF8552 !important;
  border-color: #fff !important;
  color: #fff !important;
}
