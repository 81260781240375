@use "src/assets/styles/variables";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow-y: auto;
}

.error {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-self: center;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 20px;

  div {
    display: flex;
    flex-direction: column;
    gap: 20px;

    p {
      font-size: 20px !important;
      font-family: 'Chillax-Regular', sans-serif !important;
      color: variables.$footer_primary_color;
    }

    button {
      width: 250px !important;
      align-self: center !important;
      background-color: variables.$footer_primary_color !important;
      color: #fff !important;
      text-transform: none !important;
    }
  }

  @media screen and (min-width: variables.$media_l) {
    flex-direction: row;
  }
}

.logo {
  height: 100px;
  width: 100px;
}

.title {
  font-size: 32px !important;
  font-family: 'Chillax-Semibold', sans-serif !important;
}

.tableCellMapFirst {
  font-family: "Chillax-Semibold", sans-serif !important;
  color: #474CAE !important;
  font-size: 18px !important;
}

.tableCellMap {
  text-align: left !important;
  font-family: "Chillax-Semibold", sans-serif !important;
  color: #474CAE !important;
  font-size: 18px !important;
}

.tableCellFirst {
  color: #474CAE !important;
  font-family: "Chillax-Regular", sans-serif;
  font-size: 16px !important;
}

.tableCell {
  color: #474CAE !important;
  text-align: left !important;
  font-family: "Chillax-Regular", sans-serif !important;
  font-size: 16px !important;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.button {
  border-radius: 12px !important;
  color: #fff !important;
  font-family: 'Chillax-Medium' !important;
  font-size: 18px !important;
  text-transform: none !important;
  cursor: pointer !important;
}

.button:disabled {
  background-color: #585858 !important;
  cursor: not-allowed !important;
}

.contentAcceptButton {
  @extend .button;

  background-color: #049798 !important;
}

.contentDeclineButton {
  @extend .button;

  background-color: #F58B74 !important;
}
