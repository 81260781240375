@use "src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (min-width: variables.$media_m) {
    flex-direction: row;
  }
}

.column {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (min-width: variables.$media_m) {
    width: 400px;
  }
}

.map {
  -webkit-box-shadow: 0 0 17px -11px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0 0 17px -11px rgba(66, 68, 90, 1);
  box-shadow: 0 0 17px -11px rgba(66, 68, 90, 1);
  height: 350px;

  @media screen and (min-width: variables.$media_m) {
    border-radius: 20px;
    border: 6px solid #fff;
    width: 70%;
    height: 500px;
  }
}
