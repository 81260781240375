@use 'src/assets/styles/variables';

.popup {
  width: 350px !important;
  height: 250px !important;
  background-color: #F8EDE5 !important;
  border-radius: 12px !important;
  max-width: 85vw !important;

  a {
    z-index: 99999 !important;
    background: #F8EDE5 !important;
  }

  @media screen and (min-width: 400px) {
    height: 300px !important;
  }
}
