@use 'src/assets/styles/variables';

.container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  background: #F8EDE5;
}

.navbarContainer {
  background-size: cover;
  min-height: calc(100vh - 100px);
  background: #F8EDE5;
}

.content {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 100px);
}

.navbarOnContent {
  transform: translateY(-100px);
}

.navbar {
  width: 100%;
  transition: transform 0.5s ease-in-out;
}

.navbarOpen {
  transform: translateY(-110%);
  
  @media screen and (min-width: variables.$media_l) {
    transform: unset;
  }
}
