@use "src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: variables.$navbar-height;
  color: #474CAE !important;
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  padding: 0 40px;
  justify-content: center;

  @media screen and (min-width: variables.$media_l) {
    flex-direction: row;
  }
}

.logo {
  width: 100px;
  height: 100px;
  cursor: pointer !important;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center !important;

  @media screen and (min-width: variables.$media_l) {
    text-align: left !important;
  }
}
