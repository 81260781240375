@use "src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.control {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  right: 0;

  * {
    fill: #868ad9;
  }
}

.content {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (min-width: variables.$media_l) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    gap: 32px;
  }
}

.fieldWrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 24px 1fr;
  position: relative;
  min-height: 100px;
}

.tooltip {
  position: absolute !important;
  top: 0 !important;
  right: -32px !important;

  @media screen and (min-width: variables.$media_l) {
    right: 0 !important;
  }
}

.tooltipRight {
  @extend .tooltip;

  right: -32px !important;

  @media screen and (min-width: variables.$media_l) {
    right: -24px !important;
  }
}

.title {
  margin-left: 8px !important;
  font-family: 'Chillax-Semibold' !important;
  color: #474CAE !important;
  font-size: 24px !important;

  @media screen and (min-width: variables.$media_l) {
    font-size: 32px !important;
  }
}

.itemTitle {
  @extend .title;

  font-size: 14px !important;

  @media screen and (min-width: variables.$media_l) {
    font-size: 16px !important;
  }
}

.itemTitleCenter {
  @extend .itemTitle;

  text-align: center !important;
}


.titleError {
  color: #ec2a00 !important;
}
