.container {
  display: grid;
  grid-template-columns:0.7fr 0.3fr;
  gap: 16px;
}

.leftSection {
  display: flex;
  align-items: center;
}

.sectionWrapper {
  display: flex;
  flex-direction: column;
  padding: 0 4px;
}

.leftSectionWrapper {
  @extend .sectionWrapper;
}

.image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 12px;
}

.rightSection {
  @extend .sectionWrapper;

  text-align: right !important;
}

.bold {
  font-family: 'Chillax-semibold' !important;
  color: #474CAE !important;
}

.light {
  font-family: 'Chillax-light' !important;
  color: #474CAE !important;
}
