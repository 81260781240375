.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  max-width: 600px;
  margin: 0 auto;
  padding: 16px;
}

.logo {
  width: 100px;
  height: 100px;
}

.copy {
  font-family: 'Chillax-Medium' !important;
  color: #474CAE !important;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.button {
  width: 130px !important;
  height: 40px !important;
  border: 3px solid !important;
  font-family: 'Chillax-Semibold' !important;
  -webkit-box-shadow: 0 0 8px -4px rgba(66, 68, 90, 1) !important;
  -moz-box-shadow: 0 0 8px -4px rgba(66, 68, 90, 1) !important;
  box-shadow: 0 0 8px -4px rgba(66, 68, 90, 1) !important;
  text-transform: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.button:disabled {
  background-color: #cecece !important;
}

.leftButton {
  @extend .button;

  border-color: #fff !important;
  background-color: #FF8552 !important;
  color: #fff !important;
}

.rightButton {
  @extend .button;

  border-color: #474CAE !important;
  color: #474CAE !important;
}


.text {
  color: #474CAE !important;
  font-family: 'Chillax-Semibold' !important;
  text-align: center !important;
}
