.container {
  min-width: 150px;
  width: 350px;
  height: 250px;
  overflow-y: auto;
  position: absolute !important;
  left: 0;
  top: 0;
  border-radius: 12px !important;
  max-width: 85vw !important;

  @media screen and (min-width: 400px) {
    height: 300px !important;
  }
}

.content {
  display: grid;
  grid-template-rows: 73% 27%;
  gap: 0;
  grid-template-columns: 1fr;
  height: 100%;
  width: 100%;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.details {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 24px;

  p {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.itemWrapper {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}

.textBold {
  font-family: 'Chillax-bold' !important;
  font-size: 22px !important;
}

.title {
  @extend .textBold;

  color: #474CAE !important;
}

.price {
  @extend .textBold;

  color: #474CAE !important;
}

.text {
  font-family: 'Chillax-Medium' !important;
  font-size: 14px !important;
  color: #6E7095 !important;
}

.address {
  @extend .text;

  font-family: 'Chillax-Semibold' !important;
}

.currency {
  @extend .text;

  font-family: 'Chillax-Medium' !important;
}
