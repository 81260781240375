.container {
  width: 100%;
  position: fixed;
  bottom: 0;
  top: 200px;
  left: 0;
  overflow: hidden;
}

.iframe {
  height: 100%;
}

.loader {
  width: 100%;
  position: fixed;
  bottom: 0;
  top: 200px;
  left: 0;
  overflow: hidden;
}
