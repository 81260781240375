@use 'src/assets/styles/variables';

.container {
  width: 100%;
  min-height: variables.$navbar-height;
  padding-top: 16px;

  @media screen and (min-width: variables.$media_l) {
    display: grid;
    grid-template-columns: 0.5fr 1.5fr;
    grid-template-rows: repeat(4, 1fr);
    max-width: 1500px;
    margin: 0 auto;
    padding: 16px;
  }

  @media screen and (min-width: variables.$media_xl) {
    grid-template-columns: repeat(3, 1fr);
  }
}

// positioning
.header {
  @media screen and (min-width: variables.$media_l) {
    grid-column: 1/2;
    grid-row: 1/5;
  }
}

.navigation {
  @media screen and (min-width: variables.$media_l) {
    grid-column: 2/3;
    grid-row: 1/2;
    align-self: flex-end;
  }
}

.content {
  @media screen and (min-width: variables.$media_l) {
    grid-column: 2/3;
    grid-row: 2/5;
  }
}

.info {
  display: none;

  @media screen and (min-width: variables.$media_xl) {
    display: flex;
    grid-column: 3/4;
    grid-row: 1/4;
    background: #FCF9F6;
    width: 80%;
    height: max-content;
    align-self: center;
    justify-self: flex-end;
    border-radius: 16px;
    padding: 16px;
    flex-direction: column;
    gap: 8px;

    h3 {
      color: #FF8552 !important;
      text-align: left !important;
      font-size: 20px !important;
      font-family: 'Chillax-semibold' !important;
    }

    p {
      color: #A06C41 !important;
      text-align: left !important;
      font-size: 16px !important;
      font-family: 'Chillax-regular' !important;
    }
  }
}

.form {
  width: 100%;
  height: 100%;
  position: relative;
}

.closeButton {
  position: absolute !important;
  top: -16px !important;
  right: -16px !important;
  color: #fff !important;
  background-color: #FF8552 !important;
  width: 30px !important;
  height: 30px !important;
}

.loader {
  height: 50vh;

  @media screen and (min-width: variables.$media_xl) {
    grid-row: 2/3;
    height: unset;
  }
}
