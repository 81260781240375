@use "src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  max-width: 450px;

  img {
    width: 100%;
    border-radius: 15px;
  }

  @media screen and (min-width: variables.$media_xl) {
    max-width: unset;
  }
}

.wrapper {
  display: grid;
  gap: 20px;
}

.photos {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  div {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.icon {
  width: 20px !important;
  height: 20px !important;

  & > * {
    fill: #049798 !important;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 6px;

  span {
    font-family: 'Chillax-Medium' !important;
  }
}

.rating {
  transform: translateX(-4px) !important;
  opacity: 1 !important;

  * {
    fill: #049798 !important;
  }
}
