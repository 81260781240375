@use "src/assets/styles/variables";

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-top: 30px;
  border-bottom: 1px solid;
  border-top: 1px solid;

  @media screen and (min-width: variables.$media_l) {
    justify-content: flex-start;
    max-width: 1930px;
    width: 92%;
    margin-left: auto;
    margin-right: auto;
    gap: 8px;
  }
}

.icon {
  width: 50px;
  height: 50px;

  svg {
    width: 50px;
    height: 50px;
  }

  @media screen and (min-width: variables.$media_l) {
    width: 60px;
    height: 60px;

    svg {
      width: 60px;
      height: 60px;
    }
  }
}
