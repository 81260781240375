@use "src/assets/styles/variables";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;

  @media screen and (min-width: variables.$media_l) {
    min-width: 400px;
  }
}

.header {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 0.3fr;
}

.modalName {
  font-family: 'Chillax-semibold' !important;
  color: #474CAE !important;
  font-size: 28px !important;
}

.headerButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.iconButton {
  height: 30px !important;
  width: 30px !important;
  color: #474CAE !important;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;

  p {
    margin-left: 8px !important;
    color: #474CAE !important;
    font-family: 'Chillax-Semibold' !important;
    font-size: 10px !important;
  }

  input {
    color: #474CAE !important;
    font-family: 'Chillax-Medium' !important;
    height: 40px !important;
    border-color: #474CAE !important;
  }

  textarea {
    color: #474CAE !important;
    font-family: 'Chillax-Medium' !important;
    font-size: 16px !important;
    min-height: 40px !important;
    border-color: #474CAE !important;
  }

  @media screen and (min-width: variables.$media_l) {
    input {
      height: 60px !important;
    }

    input {
      height: 60px !important;
    }

    textarea {
      height: 60px !important;
    }

    p {
      font-size: 16px !important;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (min-width: variables.$media_l) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.select {
  * {
    color: #474CAE !important;
    font-family: 'Chillax-Medium' !important;
  }

  div {
    height: 60px !important;
    overflow-y: auto;
    display: flex;
    gap: 0;
  }
}

.timePicker {
  * {
    color: #474CAE !important;
    font-family: 'Chillax-Medium' !important;
  }
}

.popup {
  z-index: 999999999999999;

  * {
    color: #474CAE !important;
    font-family: 'Chillax-Medium' !important;
  }
}

.rowWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  @media screen and (min-width: variables.$media_l) {
    grid-column: 1/3;
  }
}

.datePicker {
  * {
    color: #474CAE !important;
    font-family: 'Chillax-Medium' !important;
  }
}