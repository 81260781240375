@use "src/assets/styles/variables";

.container {
  width: 100%;
  height: 100%;
  background-color: #474CAE;
  min-height: 100%;
  padding: 8px 20px;
  padding-bottom: 24px;
  border-radius: 16px;
  border: 3px solid #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  @media screen and (min-width: variables.$media_l) {
    height: 100%;
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  background-color: #595EB6;
  border-radius: 12px;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 24px;
}

.content {
  background-color: #fff;
  padding: 8px 12px;
  border-radius: 28px;
  height: 100%;
  gap: 16px;
  overflow-y: auto;
}

.header {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: grid;
  grid-template-rows:  1fr 1fr;
  gap: 8px;

  @media screen and (min-width: variables.$media_l) {
    display: flex;
  }
}

.buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  grid-row: 2/3;
  overflow-x: scroll;
}

.actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.actionWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.button {
  background-color: #FCF9F6 !important;
  color: #000 !important;
  border-radius: 50px !important;
  padding: 12px 16px !important;
  transition: background-color 0.4s, color 0.4s;
  font-family: 'Chillax-bold' !important;
  text-transform: none !important;
}

.activeButton {
  background-color: #ED8665 !important;
  color: #474CAE !important;
}

.actionButton {
  background-color: #FF8552 !important;
  color: #fff !important;
  font-family: 'Chillax-semibold' !important;
  text-transform: none !important;
  padding: 8px 24px !important;
}

.availability {
  text-transform: uppercase !important;
  color: #fff !important;
  font-size: 20px !important;
  font-family: 'Chillax-semibold' !important;
}
