@use "src/assets/styles/variables";

.container {
  height: variables.$navbar-height;
  display: grid;
  grid-template-rows: 100px 1fr;

  @media screen and (min-width: variables.$media_l) {
    grid-template-rows: 1fr;
    grid-template-columns: 150px 1fr;
    gap: 32px;

    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    * {
      scrollbar-width: auto;
      scrollbar-color: #474cae #595eb6;
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
      width: 16px;
    }

    *::-webkit-scrollbar-track {
      background: #595eb6;
    }

    *::-webkit-scrollbar-thumb {
      background-color: #474cae;
      border-radius: 0;
      border: 0 solid #595eb6;
    }

  }
}

.containerNavOpen {
  grid-template-rows: 1fr;

  @media screen and (min-width: variables.$media_l) {
    grid-template-columns: 150px 1fr;
  }
}

.sectionWrapper {
  padding: 16px;
  overflow-y: auto;

  @media screen and (min-width: variables.$media_l) {
    padding: 8px;
    max-height: 100%;
  }
}

.sectionWrapperNavOpen {
  display: none;

  @media screen and (min-width: variables.$media_l) {
    overflow-y: auto;
    display: flex;
    min-width: 1000px;
  }
}


