@use "src/assets/styles/variables";

.container {
  gap: 0;
}

.content {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 48px;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: variables.$media_l) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    justify-content: space-between;
  }

  @media screen and (min-width: variables.$media_l) and (min-height: 700px) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 300px));
    justify-content: space-between;
    grid-template-rows: unset;
  }
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: 300px;

  h3, h4 {
    color: #4BBBB7 !important;
    text-align: center !important;
  }

  h3 {
    font-size: 24px !important;
    line-height: 24px !important;
  }

  h4 {
    font-size: 18px !important;
  }
}

.carouselContainer {
  border-radius: 16px;
  width: 100%;
}

.carousel {
  display: flex;
}

.item {
  padding: 10px;
}

.subTitle {
  font-family: 'Tanohe-Sans' !important;
  font-size: 28px !important;
  text-transform: uppercase !important;
}

