.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9999999999999;
}

.switcher {
  display: flex;
  align-items: center;
  justify-content: center;
}

.locale {
  text-transform: uppercase !important;
  color: #4764AE !important;
  font-weight: bold !important;
}

.localeGreen {
  color: #4BBBB7 !important;
}

.iconButton {

  * {
    fill: #4764AE;
  }
}

.greenIcon {

  * {
    fill: #4BBBB7;
  }
}

.menuOpen {
  position: absolute;
  top: 200%;
  left: 50%;
  overflow: hidden;
  transform: translateX(-50%);
  background-color: #FFF8F3;
  padding: 8px 16px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  -webkit-box-shadow: 8px 8px 15px -12px rgba(66, 68, 90, 1);
  -moz-box-shadow: 8px 8px 15px -12px rgba(66, 68, 90, 1);
  box-shadow: 8px 8px 15px -12px rgba(66, 68, 90, 1);
}

.menuGreen {
  border-color: #4BBBB7 !important;
}

.item {
  font-family: 'Chillax-semibold' !important;
  transition: background-color 0.3s !important;
  width: 100% !important;
  padding: 8px !important;
  border-radius: 8px !important;
  text-align: left !important;
  text-transform: none !important;
  font-size: 16px !important;
  display: flex;
  gap: 8px;
  color: #4764AE !important;

  p {
    width: 100% !important;
    border-radius: 8px !important;
    text-align: left !important;
    text-transform: none !important;
    font-size: 16px !important;
    font-family: 'Chillax-semibold' !important;
  }
}

.itemGreen {
  color: #4BBBB7 !important;
}

.icon {
  width: 28px;
  height: 28px;
}
