@use "src/assets/styles/variables";

.container {
  width: 100%;
  height: 100%;
  background-color: #474CAE;
  min-height: 100%;
  padding: 8px 20px;
  padding-bottom: 24px;
  border-radius: 16px;
  border: 3px solid #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  @media screen and (min-width: variables.$media_l) {
    height: 100%;
  }
}

.team {
  text-transform: uppercase !important;
  color: #fff !important;
  font-size: 20px !important;
  font-family: 'Chillax-semibold' !important;
}

.wrapper {
  width: 100%;
  height: 100%;
  background-color: #595EB6;
  border-radius: 12px;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 24px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  align-self: center;

  @media screen and (min-width: variables.$media_l) {
    justify-content: flex-start;
    align-self: unset;
    gap: 16px;
    margin-left: 20px;
    width: 100%;
  }
}

.button {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 8px;

  p {
    color: #fff !important;
    font-family: "Chillax-semibold" !important;
    text-transform: capitalize !important;
  }

  button {
    width: 30px !important;
    height: 30px !important;
    color: #fff !important;
    background-color: #FF8552 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border: 3px solid #fff !important;
  }
}

.content {
  background-color: #fff;
  padding: 8px 24px;
  border-radius: 28px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  overflow-y: auto;
}

.person {
  color: #474CAE !important;
  font-family: 'Chillax-semibold' !important;
  font-size: 24px !important;

  @media screen and (min-width: variables.$media_l) {
    font-size: 28px !important;
  }
}

.teamList {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  @media screen and (min-width: variables.$media_l) {
    max-width: 1100px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    overflow-x: auto;
  }
}

.legend {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  max-width: 1100px;
}

.itemWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}

.dataWrapper {
  display: grid;
  grid-auto-rows: 50px;
  gap: 8px;
  align-items: center;
  text-align: center !important;
}

.nameWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;

  img {
    width: 45px;
    height: 45px;
  }
}

.itemTitle {
  font-family: 'Chillax-Medium' !important;
  font-size: 18px !important;
  text-transform: none !important;
  display: flex !important;
  gap: 8px !important;
  align-items: center !important;
  border-radius: 4px !important;


  svg {
    width: 30px !important;
    height: 30px !important;
  }
}

.itemTitleButton {
  border: 2px solid #dedede !important;
  -webkit-box-shadow: 11px 8px 10px -13px rgba(66, 68, 90, 1) !important;
  -moz-box-shadow: 11px 8px 10px -13px rgba(66, 68, 90, 1) !important;
  box-shadow: 11px 8px 10px -13px rgba(66, 68, 90, 1) !important;
}
