.container {
  padding: 16px;
  border-radius: 16px;
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  column-gap: 8px;
  row-gap: 16px;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  grid-column: 1/2;
  grid-row: 2/3;
}

.client {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  grid-column: 1/2;
  grid-row: 1/2;
}

.time {
  width: 100%;
  display: flex;
  justify-content: space-between;

  p {
    font-family: 'Chillax-regular' !important;
    font-size: 14px !important;
  }

  :first-child {
    text-align: left !important;
  }

  :last-child {
    text-align: right !important;
  }
}

.progress {
  width: 100%;
  background: #DAD7FE;
  color: #fff;
  height: 8px;
  border-radius: 12px;
  overflow: hidden;

  div {
    width: 70%;
    background: #474CAE;
    height: 100%;
  }
}

.iconButton {
  grid-row: 4/5 !important;
  width: 40px !important;
  height: 40px !important;
  color: #CCCCCC !important;
  align-self: center !important;
  justify-self: center !important;
  transition: transform 0.3s;
}

.iconButtonOpen {
  transform: rotate(-180deg);
}

.name {
  font-size: 14px !important;
  color: #000 !important;
  font-family: 'Chillax-bold' !important;
}

.nextBooking {
  color: #595EB6 !important;
  font-size: 12px !important;
  font-family: 'Chillax-Medium' !important;
  text-align: right !important;

  span {
    font-family: 'Chillax-semibold' !important;
  }
}

.details {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.detailsAbout {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.detailsTitle {
  color: #595EB6 !important;
  font-family: 'Chillax-Medium' !important;
  font-size: 14px !important;
  text-transform: uppercase !important;
}

.detailsWrapper {
  display: flex;
  align-items: center;
  gap: 6px;

  p {
    color: #595EB6 !important;
    font-size: 12px !important;
  }

  :first-child {
    font-family: 'Chillax-semibold' !important;
  }

  :last-child {
    font-family: 'Chillax-regular' !important;
  }
}

.detailsWrapperNotes {
  display: flex;
  flex-direction: column;
  gap: 6px;

  :first-child {
    color: #595EB6 !important;
    font-family: 'Chillax-Medium' !important;
    font-size: 14px !important;
    text-transform: uppercase !important;
  }

  :last-child {
    font-size: 12px !important;
    font-family: 'Chillax-regular' !important;
    color: #595EB6 !important;
  }
}

.lastBooking {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.lastBookingTitle {
  color: #595EB6 !important;
  font-family: 'Chillax-Medium' !important;
  font-size: 14px !important;
  text-transform: uppercase !important;
}

.lastBookingOffer {
  display: flex;
  gap: 12px;

  p {
    color: #595EB6 !important;
    font-family: 'Chillax-semibold' !important;
    font-size: 14px !important;
    text-align: left !important;
  }
}

.lastBookingWrapper {
  display: flex;
  align-items: center;
  gap: 6px;

  p {
    color: #595EB6 !important;
    font-size: 12px !important;
  }

  :first-child {
    font-family: 'Chillax-semibold' !important;
  }

  :last-child {
    font-family: 'Chillax-regular' !important;
  }
}
