@use "src/assets/styles/variables";

.container {
  position: sticky;
  width: 100vw;
  background-color: #137C78;
  border: 5px solid white;
  bottom: 0;
  left: 0;
  padding: 20px;
  z-index: 999999999999;
  -webkit-box-shadow: 0 0 13px -7px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0 0 13px -7px rgba(66, 68, 90, 1);
  box-shadow: 0 0 13px -7px rgba(66, 68, 90, 1);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #fff !important;

  @media screen and (min-width: variables.$media_l) {
    width: 500px;
    left: 50px;
    bottom: 50px;
  }
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.button {
  border: 3px solid #fff !important;
  width: 130px !important;
  text-transform: none !important;
  color: #fff !important;
  font-family: 'Chillax-Regular' !important;
}

.link {
  font-family: 'Chillax-Regular' !important;
  color: #F98E57 !important;
}
