@use "src/assets/styles/variables";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 16px;
  background-color: #F8F2ED;
  border-radius: 12px;
  -webkit-box-shadow: 0px 0px 9px -6px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 0px 9px -6px rgba(66, 68, 90, 1);
  box-shadow: 0px 0px 9px -6px rgba(66, 68, 90, 1);

  @media screen and (min-width: variables.$media_m) {
    gap: 28px;
  }
}

.imageWrapper {
  width: 40%;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.boldText {
  color: #474CAE !important;
  font-family: 'Chillax-bold' !important;
  font-size: 18px !important;

  span {
    font-size: 14px !important;
  }

  @media screen and (min-width: variables.$media_m) {
    font-size: 22px !important;

    span {
      font-size: 16px !important;
    }
  }
}

.detailsText {
  color: #6E7095 !important;
  font-family: "Chillax-Medium" !important;
  font-size: 12px !important;

  @media screen and (min-width: 700px) {
    font-size: 18px !important;
  }
}

.content {
  width: 60%;
  height: 100%;
  display: grid;
  grid-template-rows: 20% 1fr 20%;
  grid-template-columns: 1fr 30%;
  padding: 8px 0;
}

.title {
  grid-column: 1/3 !important;
  font-size: 16px !important;

  @extend .boldText;
}

.details {
  grid-row: 2/3;
  grid-column: 1/2;
  display: grid;
  grid-template-rows: repeat(3, 0.3fr);
  align-items: center;

  p {
    @extend .detailsText;
  }
}

.price {
  grid-row: 3/4;
  grid-column: 1/3;

  @extend .boldText;

  font-size: 18px !important;
}

.icon {
  grid-row: 2/3;
  grid-column: 2/3;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  * {
    fill: #474CAE !important;
  }

  svg {
    width: 90% !important;
  }
}

.detailsItem {
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 8px;
  justify-content: center;
  align-items: center;

  p {
    font-family: 'Chillax-semibold' !important;
  }

  svg {
    width: 20px;
    height: 20px;

    * {
      fill: #FF8552 !important;
    }
  }

  @media screen and (min-width: variables.$media_l) {
    grid-template-columns: 30px 1fr;

    svg {
      width: 30px;
      height: 30px;
    }
  }
}

