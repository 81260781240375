.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    font-family: "Chillax-medium";
    color: #474cae;
  }
}

.offersList {
  width: 100%;
  height: 400px;
  overflow-y: auto;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.offerButton {
  border: 2px solid #fff;
  color: #fff;
  background-color: #474cae;
}

.offerButton:hover {
  border: 2px solid #fff !important;
  color: #fff !important;
  background-color: #474cae !important;
}