.container {
  p {
    font-family: 'Chillax-semibold';
    color: #474CAE;
    margin: 0;
    padding: 0;

    span {
      font-family: 'Chillax-Medium';
    }
  }
}
