.row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.logo {
  width: 40px;
  height: 40px;

  & > * {
    fill: #4BBBB7 !important;
  }
}

.purpleIcon {
  & > * {
    fill: #474CAE !important;
  }
}
