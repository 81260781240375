@use "src/assets/styles/variables";

.container {

  @media screen and (min-width: variables.$media_l) {
    margin: 64px 0;
  }
}

.content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: 200px;
  gap: 15px;

  @media screen and (min-width: variables.$media_l) {
    grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
  }
}

.carouselContainer {
  border-radius: 16px;
  overflow: unset;
}

.carousel {
  display: flex;
}

.item {
  padding: 10px;
}