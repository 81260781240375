@use "src/assets/styles/variables";

.wrapper {
  z-index: 99999999999999 !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
}

.container {
  background-color: #fcf9f6;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  border-radius: 14px !important;
  padding: 30px;
  width: 90%;
  max-width: 600px;
  max-height: 80%;
  overflow-y: auto;

  @media screen and (min-width: variables.$media_l) {
    width: unset;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #d2d2d2;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #d2d2d2;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #000000;
  }
}
