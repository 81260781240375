@use 'src/assets/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media screen and (min-width: variables.$media_l) {
    min-width: 500px;

  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalTitle {
  font-family: 'Chillax-semibold' !important;
  color: #474CAE !important;
  font-size: 32px !important;
}

.closeButton {
  height: 50px !important;

  svg {
    width: 100% !important;
    height: 100% !important;
  }
}

.sendButton {
  font-family: 'Chillax-Medium' !important;
  font-size: 14px !important;
  align-self: center !important;
  background-color: #FF8552 !important;
  color: #fff !important;
  border: 3px solid #fff !important;
  border-radius: 12px !important;
  width: 180px !important;
  text-transform: none !important;
}
