@use "src/assets/styles/variables";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  * {
    scrollbar-width: auto !important;
    scrollbar-color: #ffffff #595eb6 !important;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px !important;
    height: 4px !important;
  }

  *::-webkit-scrollbar-track {
    background: #595eb6 !important;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #ffffff !important;
    border-radius: 3px !important;
    border: 0 solid #ffb8b8 !important;
  }

  @media screen and (min-width: variables.$media_l) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    height: calc(100% - 24px);
  }
}

// grid positioning

.title {
  @media screen and (min-width: variables.$media_l) {
    grid-column: 1/2;
    grid-row: 1/2;
  }
}

.description {
  @media screen and (min-width: variables.$media_l) {
    grid-column: 1/3;
    grid-row: 2/3;
  }
}

.floatingSection {
  @media screen and (min-width: variables.$media_l) {
    grid-column: 2/3;
    grid-row: 1/2;
  }
}

.photos {
  @media screen and (min-width: variables.$media_l) {
    grid-column: 1/3;
    grid-row: 3/4;
  }
}

.textFieldBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px 16px;
  background-color: #595eb6;
  border-radius: 16px;
  gap: 8px;

  h3, p {
    color: #fff !important;
  }

  h3 {
    font-family: 'Chillax-Semibold' !important;
    font-size: 16px !important;
    align-self: flex-start !important;
    margin-left: 16px !important;
  }

  p {
    font-family: 'Comfortaa' !important;
    font-size: 12px !important;
    align-self: flex-end !important;
    margin-right: 16px !important;
    margin-left: 16px !important;
  }

  @media screen and (min-width: variables.$media_l) {
    padding: 8px 48px;
  }
}

.wrapper {
  display: flex;
  gap: 16px;
  align-items: center !important;
  width: 100%;
  height: 20px;
}

.helpers {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  width: 100%;

  .error {
    color: #ec3636 !important;
  }
}

.additionalDetails {
  background-color: #595eb6;
  display: grid;
  grid-template-columns: 1fr 100px;
  gap: 24px;
  padding: 16px;
  border-radius: 16px;

  @media screen and (min-width: variables.$media_l) {
    grid-template-columns: 1fr;
    padding: 8px 48px;
  }
}

.detailsContent {
  display: flex;
  flex-direction: column;
  gap: 54px;
  padding-bottom: 32px;

  p {
    margin-left: 16px !important;
    font-family: 'Chillax-Semibold' !important;
    color: #fff !important;
  }

  @media screen and (min-width: variables.$media_l) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 8px;
    column-gap: 24px;
    padding-bottom: 0;
  }
}

.detailsHeader {
  display: flex;
  flex-direction: column;
  gap: 0;

  img {
    height: 60px;
  }

  p {
    font-size: 14px !important;
  }

  @media screen and (min-width: variables.$media_l) {
    p {
      font-size: 16px !important;
    }
  }
}

.additionalDetailsTextField {
  display: flex;
  flex-direction: column;
  gap: 8px;

  h3 {
    font-family: 'Chillax-Medium' !important;
    font-size: 16px !important;
    color: #fff !important;
    align-self: flex-start !important;
    margin-left: 16px !important;
  }

  p {
    color: #ec3636 !important;
    font-family: 'Comfortaa' !important;
    font-size: 12px !important;
    align-self: flex-start !important;
    margin-right: 16px !important;
  }
}

.noDetailsIcon {
  display: none;

  @media screen and (min-width: variables.$media_l) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    img {
      height: 180px;
    }
  }
}

.mobileIcon {
  width: 100%;
}

.error {
  align-self: flex-start !important;
}

.counter {
  align-self: flex-end !important;
  text-align: end !important;
  grid-column: 2/3 !important;
  font-family: 'Chillax-Medium' !important;
  font-size: 12px !important;
  color: #fff !important;
  padding-right: 16px !important;
}
