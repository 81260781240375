@use "src/assets/styles/variables";

.container {
  background-color: #F8EDE5;
  width: 100%;
  display: grid;
  grid-template-rows: 240px 1fr;
  gap: 16px;
  padding: 20px;
  border-radius: 30px;
  overflow: hidden;
  -webkit-box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25);
  box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25);
}

.imageWrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 16px;
}


.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform .4s;
}

.container:hover .image {
  @media screen and (min-width: variables.$media_m) {
    transform: scale(1.1);
  }
}

.content {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
}

.contentTwoRows {
  @extend .content;

  grid-template-rows: repeat(2, 1fr);
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  color: #84CFCD !important;
  font-size: 24px !important;
  font-family: 'Chillax-bold' !important;
}

.discount {
  color: #84CFCD !important;
  font-size: 24px !important;
  font-family: 'Chillax-Semibold' !important;
}

.address {
  color: #F9AD94 !important;
  font-size: 16px !important;
  font-family: 'Chillax-Semibold' !important;
}

.price {
  color: #F9AD94 !important;
  font-size: 24px !important;
  font-family: 'Chillax-bold' !important;
}

.dates {
  color: #F9AD94 !important;
  font-size: 16px !important;
  font-family: 'Chillax-Semibold' !important;
}

.pricePer {
  color: #F9AD94 !important;
  font-size: 16px !important;
  font-family: 'Chillax-Medium' !important;
}
