@use "src/assets/styles/variables";

.container {
  background-color: #F8EDE5;
  margin-top: -40px;
  padding-bottom: 100px;

  @media screen and (min-width: variables.$media_l) {
    margin-top: 0;
    padding-bottom: 0;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
  font-family: "Chillax-Regular", sans-serif !important;
  color: #333 !important;
  background-color: #F8EDE5;

  @media screen and (min-width: variables.$media_m) {
    width: 90%;
    margin: 0 auto;
    gap: 70px;
    max-width: 1900px;
  }
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-self: center;
  background-color: #F8F2ED;
}

.desktop {
  display: none;

  @media screen and (min-width: variables.$media_m) {
    display: flex;
  }
}

.mobile {
  @media screen and (min-width: variables.$media_xl) {
    display: none;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: variables.$media_xl) {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 450px;
  }
}

.sectionContainer {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.floatingSection {
  display: none;

  @media screen and (min-width: variables.$media_xl) {
    transform: translateY(-20%);
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    height: 100%;
    z-index: 9;
  }
}

.modal {
  width: 100%;
  padding: 0 !important;
  height: 80%;
  margin: 0;
  max-height: 70vh !important;

  @media screen and (min-width: variables.$media_xl) {
    max-width: unset !important;
    width: max-content !important;
    height: max-content !important;
    top: 55% !important;
    max-height: 70vh !important;
    padding: 16px !important;
  }
}

.navbar {
  position: absolute;
  z-index: 999;

  @media screen and (min-width: variables.$media_m) {
    position: unset;
  }
}
