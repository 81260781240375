@use "src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 20px;

  @media screen and (min-width: variables.$media_l) {
    display: grid;
    grid-template-columns: 400px 1fr;
    padding: 40px 100px;
    min-height: calc(100vh - 100px);
    gap: 100px;
  }
}

.articles {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;

  @media screen and (min-width: variables.$media_l) {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-auto-rows: max-content;
    gap: 20px;
  }
}

.articleBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > * {
    color: #1F1A16 !important;
  }


  div {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    & > * {
      font-family: 'Chillax-Regular' !important;
    }
  }

  h4 {
    font-size: 18px !important;
  }

  h5 {
    font-size: 14px !important;
  }

  img {
    width: 100%;
    border-radius: 20px;
    height: 150px;
    object-fit: cover;
    -webkit-box-shadow: 8px 8px 8px -10px rgba(66, 68, 90, 1);
    -moz-box-shadow: 8px 8px 8px -10px rgba(66, 68, 90, 1);
    box-shadow: 8px 8px 8px -10px rgba(66, 68, 90, 1);
  }

  @media screen and (min-width: variables.$media_l) {
    height: 100%;

    img {
      height: 250px;
    }
  }
}

.loader {
  width: 100%;
  height: calc(100vh - 100px);

  @media screen and (min-width: variables.$media_l) {
    grid-column: 1/3;
    height: 100%;
  }
}