.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.text {
  width: max-content !important;
}

.header {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.tooltip {
  width: 30px !important;
  height: 30px !important;
  cursor: pointer !important;
}