@use 'src/assets/styles/variables';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 90%;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: variables.$media_l) {
    margin: 32px 0;
    height: 100%;
  }
}

.legend {
  color: #FF8552 !important;
  font-family: 'Chillax-semibold' !important;
  text-align: center !important;
  width: 100% !important;
}

.noData {
    font-family: 'Chillax-Semibold' !important;
    color: #fff !important;
    font-size: 22px !important;
    text-transform: uppercase !important;
}