.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

.closeButton {
  position: absolute !important;
  right: 8px !important;
  top: 8px !important;
}

.input {
  width: 100% !important;
}

.button {
  color: #fff !important;
  background-color: #FF8552 !important;
  border-radius: 14px !important;
  font-family: 'Chillax-semibold' !important;
  text-transform: none !important;
  margin-top: 24px !important;
}
