@use "src/assets/styles/variables";

.counter {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  gap: 16px;
}

.button {
  width: 50px !important;
  height: 50px !important;
  color: #595EB6 !important;
  font-size: 48px !important;
  min-width: unset !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  font-weight: bold !important;
}

.value {
  text-align: center !important;
  grid-column: 1/3 !important;
  width: 100% !important;
  font-family: "Chillax-Semibold" !important;
  font-size: 36px !important;
}

.error {
  font-size: 16px !important;
  text-align: center;
  position: absolute;
  bottom: -40px;
  width: 200%;
  color: #ff3333 !important;

  @media screen and (min-width: variables.$media_l) {
    width: 150%;
  }
}
