@use "src/assets/styles/variables";

.container {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 30px;
  background-color: #F8EDE5;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #F8EDE5;
  max-width: 1700px;
  align-self: center;
  width: 100%;
  position: relative;
  transform: translateY(-20px);

  @media screen and (min-width: variables.$media_l) {
    gap: 100px;
  }
}

.companyWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.separator {
  min-height: 400px;
  max-height: 500px;
  margin-bottom: -40px;
  z-index: 50;

  @media screen and (min-width: variables.$media_l) {
    min-height: 500px;
  }
}

.ropeIcon {
  display: none;

  @media screen and (min-width: variables.$media_xl) {
    display: block;
    top: -28%;
    position: absolute;
    left: -20%;
  }
}

.ropeIconSecond {
  display: none;
  z-index: 0;

  @media screen and (min-width: variables.$media_xl) {
    display: block;
    top: -20%;
    position: absolute;
    left: -40%;
  }
}

// children styles

.itemContainer {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0 20px;
  gap: 10px;
  position: relative;

  @media screen and (min-width: variables.$media_l) {
    padding: 20px 50px;
    gap: 24px;
  }

  @media screen and (min-width: variables.$media_xxl) {
    padding: 20px 80px;
  }
}

.itemHeader {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.itemTitle {
  font-size: 28px !important;
  color: #FDAF96 !important;
  font-family: Tanohe-Sans, sans-serif !important;

  @media screen and (min-width: variables.$media_l) {
    font-size: 60px !important;
    line-height: 74px !important;
  }

  @media screen and (min-width: variables.$media_xl) {
    font-size: 68px !important;
    line-height: 80px !important;
  }

  @media screen and (min-width: variables.$media_xxl) {
    font-size: 78px !important;
  }

  @media screen and (min-width: variables.$media_xxl) {
    font-size: 82px !important;
  }

  @media screen and (min-width: variables.$media_hd) {
    font-size: 92px !important;
  }
}

.itemSubtitle {
  font-size: 20px !important;
  color: #4BBBB7 !important;
  font-family: Chillax-semibold, sans-serif !important;

  @media screen and (min-width: variables.$media_l) {
    font-size: 22px !important;
  }

  @media screen and (min-width: variables.$media_xxl) {
    font-size: 24px !important;
  }
}

.itemSubtitleMedium {
  @extend .itemSubtitle;
  
  font-family: 'Chillax-Medium' !important;
}

.itemButton {
  display: block !important;
  width: 180px !important;
  height: 60px !important;
  background-color: #ff8552 !important;
  color: #fff !important;
  text-transform: none !important;
  font-size: 18px !important;
  align-self: center !important;
  z-index: 999 !important;
  transition: box-shadow 0.4s !important;
  margin-top: 38px !important;
  margin-bottom: 0 !important;
  border-radius: 50px !important;
  font-family: 'Chillax-semibold' !important;
  -webkit-box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25) !important;
  -moz-box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25) !important;
  box-shadow: 1.5px 1.5px 0px 1px rgba(0, 0, 0, 0.25) !important;

  &:hover {
    -webkit-box-shadow: 0 0 13px -4px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0 0 13px -4px rgba(66, 68, 90, 1);
    box-shadow: 0 0 13px -4px rgba(66, 68, 90, 1);
  }

  @media screen and (min-width: variables.$media_l) {
    align-self: flex-end !important;
  }
}

.icon {
  display: none !important;

  @media screen and (min-width: variables.$media_xl) and (min-height: 700px) {
    display: block !important;
    top: 5%;
    right: -20%;
    position: absolute;
  }
}

.flowersIcon {
  display: none !important;

  @media screen and (min-width: variables.$media_xl) {
    display: block !important;
    bottom: 30%;
    left: -28%;
    position: absolute;
  }
}


