.wrapper {
  width: 100%;
  height: 4px;
  background-color: #CAC59B;
}

.progress {
  background: linear-gradient(95.09deg, #cac59b 12.84%, #d59267 67.6%, #ec2a00 138.88%);
  height: 100%;
  transition: width 0.4s ease;
}
