.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
}

.title {
  font-size: 20px !important;
}

.content {
  padding: 0 25px;
}
