.ant-select-selector {
    height: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    color: #474CAE !important;
    font-family: 'Chillax-Medium' !important;
    border-color: #474CAE !important;
}

.ant-select {
    height: 100%;
    border-color: #474CAE !important;
}
