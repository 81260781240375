.container {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
}

.watersportButton {
  padding: 8px !important;
  margin: 0 !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  gap: 16px !important;
  border-radius: 16px !important;
  cursor: pointer !important;

  img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    border-radius: 16px;
  }

  p {
    font-family: 'Chillax-Medium' !important;
    color: #474CAE !important;
  }
}

.watersportButtonSelected {
  background-color: #FF8552 !important;
}
