.ant-select-selector {
    background-color: transparent !important;
    border: none !important;
}

.ant-select {
    border: none !important;
}

.ant-select-selection-search-input {
    width: 100% !important;
    height: 100% !important;
    color: white !important;
}

