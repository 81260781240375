@use "src/assets/styles/variables";

.wrapper {
  width: 100%;
  padding: 48px 0 0 0;
  align-self: flex-end;
  justify-self: flex-end;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 2200px;
  margin-bottom: 20px;

  @media screen and (min-width: 1280px) {
    flex-direction: row-reverse;
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
}

.sectionName {
  color: variables.$footer_primary_color;
}

.copyright {
  text-align: center !important;
  font-size: 16px !important;
}

.copyrightWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.icon {
  width: 25px !important;
  fill: #4BBBB7 !important;
  height: 20px !important;
  margin: 0 !important;
  padding: 0 !important;
  margin-bottom: 2px !important;
}
